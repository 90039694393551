import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";

export default function FaqItem({ faq }) {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded((cv) => !cv);
  };

  return (
    <Accordion
      key={faq.id}
      expanded={expanded}
      onChange={handleClick}
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        borderRadius: "25px", // Add border radius for container
        overflow: "hidden", // Ensure rounded corners are respected
        border: "1px solid #ddd", // Border to create a clean edge
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow for container
        marginBottom: "3px", // Add spacing between accordion items
        "&:first-child": {
          borderTopLeftRadius: "25px", // Rounded top-left corner for the first container
          borderTopRightRadius: "25px", // Rounded top-right corner for the first container
        },
        "&:last-child": {
          borderBottomLeftRadius: "25px", // Rounded bottom-left corner for the last container
          borderBottomRightRadius: "25px", // Rounded bottom-right corner for the last container
        },
      }}
    >
      <AccordionSummary
        sx={{
          borderBottom: "0px solid #ddd", // Add border bottom for summary
          paddingX: "20px", // Horizontal padding
          paddingY: "10px", // Vertical padding
          fontSize: "20", // Larger font size
          fontWeight: 600, // Bold font weight
          color: "#333", // Slightly greyer color for the title
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        {faq.question}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "20px", color: "#333" }}>
        {/* Less saturated blue color for the answer content */}
        <ReactMarkdown>{faq.answer}</ReactMarkdown>
      </AccordionDetails>
    </Accordion>
  );
}
