import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import FooterLinks from "./FooterLinks";

export default function Footer() {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative" // Add relative positioning to the container
        sx={{
          backgroundColor: "#f0faff", // Use rgba with an alpha value for transparency
          color: "#f0faff",
          height: 220,
          maxHeight: 240,
          fontFamily: "OpenSans SemiBold",
          paddingInline: 3,
        }}
      >
        {/* Add a horizontal line at the top */}
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%" // Set the width to 100% to span the entire container
          content="''"
        />

        <Stack spacing={2} direction="column" alignItems="center">
          <FooterLinks />
        </Stack>
      </Box>
    </Container>
  );
}
