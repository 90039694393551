import React, { useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ExpandableCard({ title, above, below, isExpanded }) {
  const [expanded, setExpanded] = useState(isExpanded ?? false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const items = below.split("\n\n");

  return (
    <Box
      width={{ xs: 285, sm: 385, md: 485, lg: 585 }}
      display="flex"
      flexDirection="column"
      minHeight={250}
      alignItems="center"
      justifyContent="center"
      boxShadow="0.3em 0.3em 1em rgba(0, 0, 0, 0.3)"
      borderRadius={10}
      padding={1}
      sx={{ background: "#ffffff" }}
    >
      <CardContent>
        <Typography
          paragraph
          variant="h2"
          color="#57606f"
          align="center"
          fontWeight="bold"
          fontSize={20}
          fontFamily="NotoSans Regular"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="#57606f"
          fontSize={16}
          fontFamily="NotoSans Regular"
        >
          {above}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ marginTop: "auto" }}>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {items.map((item, i) => {
            const isItalic = item.startsWith("ITALIC");
            const fontStyle = isItalic ? "italic" : "normal";
            const text = item.startsWith("ITALIC")
              ? item.replace("ITALIC", "")
              : item;

            return (
              <Typography
                paragraph={i < items.length - 1}
                key={i}
                color="#57606f"
                fontSize={16}
                fontStyle={fontStyle}
                fontFamily="NotoSans Regular"
              >
                {text}
              </Typography>
            );
          })}
        </CardContent>
      </Collapse>
    </Box>
  );
}

export default ExpandableCard;
