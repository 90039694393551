import React from "react";
import Grid from "@mui/material/Grid";
import ExpandableCard from "./ExpandableCard";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

function SecondSection() {
  const { t, i18n } = useTranslation();
  const { isReasonExpanded } = useParams();

  const cardItems = [
    {
      title: t("WhyToGetJoinHeader"),
      above: t("WhyToGetJoinTextOne"),
      below: t("WhyToGetJoinTextTwo"),
      isExpanded: isReasonExpanded === "true",
    },
    {
      title: t("OurHistoryHeader"),
      above: t("OurHistoryTextOne"),
      below: t("OurHistoryTextTwo"),
      isExpanded: false,
    },
  ];

  return (
    <Stack
    alignItems="center"
    sx={{
      background: "linear-gradient(to bottom, white, lightblue)", // Add this line for the gradient
    }}
    >
      <Typography
        variant="h1"
        component="h1"
        align="center"
        color="rgb(10, 61, 98)"
          fontSize={{ xs: 22, md: 32 }}
          fontFamily={
            i18n.language === "el" ? "Manrope Regular" : "Montserrat Regular"
          }
        paddingTop={4}
        paddingBottom={4}
      >
        {t("SomethingAboutUs")}
      </Typography>
       {/* Divider line */}
       <Box
          sx={{
            width: "80%",
            height: "3px",
            backgroundColor: "#3f6891", // Vibrant light blue
            margin: "0 auto",
            marginBottom: 6, // Space between the divider and the content below
            borderRadius: "10px", // Rounded edges
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect
            animation: "fadeIn 1s ease-in-out", // Animation effect
          }}
        ></Box>
      <Grid
        container
        paddingInline={10}
        paddingBottom={10}
        sx={{ flexGrow: 1 }}
        justifyContent="center"
        alignItems="start"
        spacing={{ xs: 4, md: 6 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {cardItems.map((item, i) => (
          <Grid item key={i}>
            <ExpandableCard
              title={item.title}
              above={item.above}
              below={item.below}
              isExpanded={item.isExpanded}
            />
          </Grid>
        ))}
      </Grid>
      </Stack>
  );
}

export default SecondSection;
