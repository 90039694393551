import React from "react";
import "../../assets/Legal.css";
import { useTranslation } from "react-i18next";
import AttributionsGreek from "./AttributionsGreek";
import Attributions from "./Attributions";

function AttributionsScreen() {
  const { i18n } = useTranslation();

  return (
    <div className="legal-container">
      {i18n.language === "el" ? <AttributionsGreek /> : <Attributions />}
    </div>
  );
}

export default AttributionsScreen;
