import React from "react";
import "../../assets/App.css";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";

function Home() {
  return (
    <div>
      <FirstSection />
      <SecondSection />
    </div>
  );
}

export default Home;
