import React from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import "../../assets/App.css";
import { Typography } from "@mui/material";

export default function PhotoPairAndText({
  photo1,
  photo2,
  photo1Title,
  photo1Alt,
  photo2Title,
  photo2Alt,
  texta,
  textb,
  textc,
  language,
  rotation = 0, // Default rotation amount is 0
}) {
  return (
    <Stack spacing={2} direction="column" alignItems="center">
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          width: "100%",
          maxWidth: 400,
        }}
      >
        <img
          src={require(`../../assets/${photo1}.PNG`)}
          alt={photo1Alt}
          title={photo1Title}
          style={{
            marginBottom: 25,
            width: "65%", // Make the image responsive
            maxWidth: 300, // Maximum width of the image
            borderRadius: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            position: "absolute",
            top: 0,
            left: 0,
            transform: `rotate(${rotation}deg)`, // Apply rotation
            zIndex: 1,
            marginLeft: "40px",
            objectFit: "contain", // Ensure the image fits within its container
          }}
        />
        <img
          src={require(`../../assets/${photo2}.PNG`)}
          alt={photo2Alt}
          title={photo2Title}
          style={{
            marginBottom: 55,
            width: "65%", // Make the image responsive
            maxWidth: 200, // Maximum width of the image
            borderRadius: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            position: "relative",
            transform: `rotate(${rotation + 15}deg)`, // Apply rotation
            marginTop: "50px",
            objectFit: "contain", // Ensure the image fits within its container
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          sx={{
            maxWidth: 500,
            fontSize: 16,
            fontFamily:
              language === "el" ? "NotoSans SemiBold" : "Montserrat SemiBold",
            letterSpacing: 1,
            color: "#2B2B2B",
            textAlign: "center",
          }}
        >
          {texta}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h4"
          component="h4"
          sx={{
            maxWidth: 500,
            fontSize: 16,
            color: "#666666",
            fontFamily: "NotoSans Regular",
            textAlign: "center",
          }}
        >
          {textb}
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: 500,
          fontSize: 16,
          color: "#666666",
          fontFamily: "NotoSans Regular",
          textAlign: "center",
        }}
      >
        {textc}
      </Box>
    </Stack>
  );
}
