const isSilent = (letter) =>
  letter.length === 1 &&
  [
    "θ",
    "Θ",
    "κ",
    "Κ",
    "ξ",
    "Ξ",
    "π",
    "Π",
    "σ",
    "Σ",
    "τ",
    "Τ",
    "φ",
    "Φ",
    "χ",
    "Χ",
    "ψ",
    "Ψ",
  ].includes(letter);

export default function elot743Transliterate(greekText) {
  const elot743DoubleMap = {
    αυ: ["av", "af"],
    Αυ: ["Av", "Af"],
    αύ: ["av", "af"],
    Αύ: ["Av", "Af"],
    ευ: ["ev", "ef"],
    εύ: ["ev", "ef"],
    Ευ: ["Ev", "Ef"],
    Εύ: ["Ev", "Ff"],
    ηύ: ["iv", "if"],
    Ηύ: ["Iv", "If"],
    ηυ: ["iv", "if"],
    Ηυ: ["Iv", "If"],
  };

  const elot743Map = {
    α: "a",
    A: "A",
    ά: "a",
    Ά: "A",
    αϊ: "aï",
    Αϊ: "Aï",
    αϋ: "ay",
    Αϋ: "Ay",
    β: "v",
    Β: "B",
    γ: "g",
    Γ: "G",
    γγ: "ng",
    Γγ: "Ng",
    γξ: "nx",
    Γξ: "Nx",
    γχ: "nch",
    Γχ: "Nch",
    δ: "d",
    Δ: "D",
    ε: "e",
    Ε: "E",
    έ: "e",
    Έ: "Ε",
    εϊ: "eï",
    Εϊ: "Eï",
    εϋ: "ey",
    Εϋ: "Ey",
    ζ: "z",
    Ζ: "Z",
    η: "i",
    ή: "i",
    Η: "I",
    Ή: "I",
    ηϋ: "iy",
    Ηϋ: "Iy",
    θ: "th",
    Θ: "Th",
    ι: "i",
    ί: "i",
    Ι: "I",
    Ί: "I",
    κ: "k",
    Κ: "K",
    λ: "l",
    Λ: "L",
    μ: "m",
    Μ: "M",
    ν: "n",
    Ν: "N",
    ξ: "x",
    Ξ: "X",
    ό: "o",
    Ό: "O",
    ο: "o",
    O: "O",
    ου: "ou",
    ού: "ou",
    Ου: "Ou",
    Ού: "Ou",
    οϊ: "oï",
    Οϊ: "Oï",
    οΐ: "oï",
    Οΐ: "Oï",
    π: "p",
    Π: "P",
    ρ: "r",
    R: "R",
    σ: "s",
    ς: "s",
    Σ: "S",
    τ: "t",
    Τ: "T",
    υ: "y",
    ύ: "y",
    Υ: "Υ",
    Ύ: "Y",
    φ: "f",
    Φ: "F",
    χ: "ch",
    Χ: "Ch",
    ψ: "ps",
    Ψ: "Ps",
    ω: "o",
    Ω: "O",
    ώ: "o",
    Ώ: "O",
  };

  let greekWords = greekText.split(" ").map((word) => word.split(""));

  function romanizeGreekWordAndJoin(greekWord) {
    let transliteratedText = "";
    let i = 0;
    while (i < greekWord.length) {
      let currentChar = greekWord[i];

      if (i < greekWord.length - 1) {
        let doubleChar = greekWord.slice(i, i + 2).join("");
        if (
          ["μπ", "Μπ"].includes(doubleChar) &&
          [0, greekWord.length - 2].includes(i)
        ) {
          transliteratedText += "b";
          i += 2;
          continue;
        }
        if (Object.hasOwn(elot743DoubleMap, doubleChar)) {
          if (
            i === greekWord.length - 2 ||
            (i < greekWord.length - 2 && isSilent(greekWord[i + 2]))
          ) {
            transliteratedText += elot743DoubleMap[doubleChar][1];
          } else {
            transliteratedText += elot743DoubleMap[doubleChar][0];
          }
          i += 2;
          continue;
        } else if (Object.hasOwn(elot743Map, doubleChar)) {
          transliteratedText += elot743Map[doubleChar];
          i += 2;
          continue;
        }
      }

      if (Object.hasOwn(elot743Map, currentChar)) {
        transliteratedText += elot743Map[currentChar];
      } else {
        transliteratedText += currentChar;
      }

      i++;
    }

    return transliteratedText;
  }

  const romanizedText = greekWords
    .map((word) => romanizeGreekWordAndJoin(word))
    .join(" ");

  return romanizedText;
}
