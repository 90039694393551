import React from "react";
import "../../assets/Legal.css";
import { useTranslation } from "react-i18next";
import TermsGreek from "./TermsGreek";
import Terms from "./Terms";

function TermsScreen() {
  const { i18n } = useTranslation();

  return (
    <div className="legal-container">
      {i18n.language === "el" ? <TermsGreek /> : <Terms />}
    </div>
  );
}

export default TermsScreen;
