const it = {
  Slogan: "Real Socializing",
  AvailableIn: "Disponibile in",
  AvailableNow: "Disponibile ora",
  Days: "GIORNI",
  Hours: "ORE",
  Minutes: "MIN",
  Seconds: "SEC",
  Features: "Caratteristiche",
  JoinGroupsOfHeader: "UNISCITI A GRUPPI DI PERSONE INTERESSANTI",
  JoinGroupsOfTextOne: "Incontrare persone non è mai stato così facile.",
  JoinGroupsOfTextTwo:
    "Visualizza gruppi di persone interessanti che si incontreranno in un certo luogo, grande evento o evento creato da utenti. Chiedi di unirti a loro o metti un like in modo che possano rispondere invitandoti.",
  FriendsAndDatesHeader: "AMICI E APPUNTAMENTI ADATTI A TE",
  FriendsAndDatesTextOne: "Incontri o solo socializzazione, la scelta è tua.",
  FriendsAndDatesTextTwo:
    "Non perdere l'opportunità di incontrare persone che corrispondono al tuo tipo. Le persone con tratti corrispondenti e preferenze di appuntamento appariranno come un Social Match. Ci piace metterti in contatto con le persone giuste per te!",
  CreateUniqueEventsHeader: "CREA I TUOI GRUPPI ED EVENTI UNICI",
  CreateUniqueEventsTextOne:
    "Ti va di organizzare una festa o magari giocare a calcio con i tuoi amici?",
  CreateUniqueEventsTextTwo:
    "Oltre a creare e unirti a gruppi in luoghi e grandi eventi che abbiamo elencato, puoi anche creare i tuoi eventi personalizzati. Fai in modo che sia enorme o mantienilo privato, la scelta è tua.",
  Legal: "Legale",
  PrivacyPolicy: "Politica sulla Privacy",
  Terms: "Termini",
  CookiePolicy: "Politica sui Cookie",
  ContactUs: "Contattaci",
  Download: "Scarica!",
  AboutUs: "Chi siamo",
  RealSocializing: "Socializzazione Reale",
  WhyToGetJoinHeader: "Perché ottenere Join?",
  WhyToGetJoinTextOne:
    "Dubitiamo che tu stia usando un'applicazione che promuova davvero la tua vita sociale. \nNessuna offesa per i grandi come Instagram o Facebook: Ad oggi ci sono alcune piattaforme “sociali” di successo, ma in realtà, un po' tuttofare e per buoni motivi. Ma offriamo una dimensione alla socializzazione che mancava nelle app sociali esistenti fino a poco tempo fa.",
  WhyToGetJoinTextTwo:
    "Affermiamo con orgoglio di essere la prima vera app sociale, perché facciamo solo una cosa e la facciamo nel miglior modo possibile: portiamo le persone insieme nella vita reale.\n\nUsare Join significa ottenere accesso a gruppi di persone che hanno pianificato di visitare aziende dell'ospitalità e del tempo libero, come bar, discoteche, caffetterie, eventi musicali e altro ancora. Sono disponibili anche feste, partite sportive e altri eventi a cui unirsi.\nOltre ad aiutarti a pianificare cose divertenti con i tuoi amici attuali, ci siamo anche concentrati sul darti l'opportunità di fare nuove amicizie e appuntamenti di qualità. Dimentica il sentirti imbarazzato, ora puoi incontrare le persone giuste con il minimo sforzo. Non possiamo davvero spiegare tutto in poche frasi, ma ci siamo assicurati di rompere il ghiaccio una volta per tutte. Join è lontano dall'essere un'altra piattaforma di appuntamenti imbarazzante che la gente si vergogna di usare.\n\nCaro utente di app sociali, anche se Join è la ventata di aria fresca di cui avevi bisogno, abbiamo assolutamente bisogno di te. Speriamo che tu goda della tua esperienza sulla nostra app.",
  OurHistoryHeader: "La nostra Storia - Join & Il Team",
  OurHistoryTextOne:
    "Il Covid-19 ha sconvolto il mondo. Quello che la quarantena ha fatto alla Grecia, in termini sociali, è stato rimuovere la socializzazione dalla vita delle persone e distruggere la sua casa, l'industria dell'ospitalità. Ecco dove entra in gioco Join.",
  OurHistoryTextTwo:
    "La verità è che le nostre relazioni umane non sono state più le stesse dopo la pandemia. Alcune sono uscite più deboli, altre più forti, mentre molte sono state abbandonate del tutto\n\n-Le persone hanno capito chi conta davvero per loro-\n\nITALIC“Detto questo, in una noiosa serata di quarantena del 2021, ho deciso di cercare un modo per collegare i bar, i club e le caffetterie che allora ci mancavano, con il bisogno delle persone di creare relazioni reali. Il risultato si chiama oggi Join, un'app mobile disponibile su App Store e Google Play e io sono Dimitris Chatzichristofas, il suo co-fondatore.\n\nITALICCreare Join non è stato un processo semplice, ed è per questo che sono grato di aver avuto due grandi sviluppatori e co-fondatori, George Bratsiotis e Chris, che hanno sviluppato l'app da zero, mentre io mi sono occupato principalmente delle attività commerciali. Abbiamo ancora molto lavoro da fare e siamo entusiasti di ciò che ci aspetta.”",
  FullName: "Nome Completo",
  Subject: "Oggetto",
  AccountOrPrivacyIssue: "Problema Account / Privacy",
  ReportInappropriateBehaviour: "Segnala Comportamento Inappropriato",
  UserComplaint: "Reclamo Utente",
  BusinessEnquiry: "Richiesta Commerciale",
  Other: "Altro",
  Message: "Messaggio",
  SubmitButton: "Invia",
  INeedHelpCreatingMyGroupQ: "Ho bisogno di aiuto per creare il mio gruppo.",
  INeedHelpCreatingMyGroupQASectionOne:
    "Innanzitutto, sappi che essere il creatore del tuo gruppo ti dà il controllo assoluto su di esso, quindi non c'è motivo di stress, anche se è il primo che stai creando. Ecco una guida semplice.",
  DateAndTimeDescrGroups:
    "Qui scegli la data e l'orario di incontro del tuo gruppo. Non confonderti con gli orari a.m.: ad esempio, domenica 00:45 potrebbe ancora sembrare sabato sera nella tua mente, ma su Join è domenica mattina, ed è così che devi inserirlo.",
  InviteYourFriendsDescrGroups:
    "Questo è il passaggio in cui inviti inizialmente i tuoi amici (non preoccuparti, puoi invitare altre persone in seguito). I membri invitati riceveranno una notifica di invito da parte tua, a cui dovranno rispondere positivamente per unirsi al gruppo. Puoi creare un gruppo senza invitare nessuno e lasciare che le persone facciano richiesta di unirsi a te.",
  MakeAStatement: "Fai una dichiarazione",
  MakeAStatementDescrGroups:
    "Questa è la tua opportunità per aggiungere un tocco personale al tuo gruppo. Può essere qualcosa come 'Stiamo festeggiando il mio compleanno' o 'Le ragazze devono indossare un vestito bianco'.",
  YourGroupIsReadyForCreation: "Il tuo gruppo è pronto per essere creato!",
  INeedHelpCreatingMyEvent: "Ho bisogno di aiuto per creare il mio evento.",
  INeedHelpCreatingMyEventASectionOne:
    "Innanzitutto, sappi che essere il creatore del tuo evento ti dà il controllo assoluto su di esso, quindi non c'è motivo di stress, anche se è il primo che stai creando. Ecco una guida semplice.",
  EventTitle: "Titolo dell'Evento",
  EventTitleDescrEvents:
    "Deve essere una breve descrizione del tuo evento. Qualcosa come '5x5 calcio a Chalandri', 'Festa in spiaggia in una località segreta' o 'Incontro informale in piazza Agia Paraskevi'.",
  DescribeYourEvent: "Descrivi il tuo Evento",
  DescribeYourEventDescrEvents:
    "Ora hai l'opportunità di descrivere il tuo evento. Includi tutti i dettagli che vorresti vedere in un evento simile creato da qualcun altro. Qualsiasi cosa importante dovrebbe essere inclusa qui. Fidati, se sei dettagliato, più persone e persone più adatte saranno interessate al tuo evento.",
  PrivateEvent: "Evento Privato",
  PrivateEventDescrEvents:
    "Tutti gli eventi personalizzati sono aperti per impostazione predefinita, il che significa che tutti gli utenti di Join possono visualizzare e interagire con il tuo evento, tranne le persone che hai bloccato. Se cambi l'evento in privato, l'accesso sarà limitato solo ai tuoi amici e alle persone che inviti. Puoi modificare la privacy del tuo evento anche dopo la sua creazione.",
  DateAndTime: "Data e Orario",
  DateAndTimeDescrEvents:
    "Qui scegli la data e l'orario di inizio del tuo evento. Non confonderti con gli orari a.m.: ad esempio, domenica 00:45 potrebbe ancora sembrare sabato sera nella tua mente, ma su Join è domenica mattina, ed è così che devi inserirlo.",
  Category: "Categoria",
  CategoryDescrEvents:
    "Abbiamo concluso che il tuo evento rientrerà probabilmente in una di queste categorie: Sport, Festa, Viaggio o Incontro. Se non riesci a collocare chiaramente il tuo evento in nessuna di queste categorie, scegli 'incontro'. Dopo tutto, un evento non può non essere un incontro.",
  InviteYourFriends: "Invita i tuoi Amici",
  InviteYourFriendsDescrEvents:
    "Questo è il passaggio in cui inviti inizialmente i tuoi amici (non preoccuparti, puoi invitare altre persone in seguito). I membri invitati riceveranno una notifica di invito da parte tua, a cui dovranno rispondere positivamente per unirsi all'evento. Puoi creare un evento senza invitare nessuno e lasciare che le persone facciano richiesta di unirsi a te.",
  YourCustomEventIsReadyForCreation:
    "Il tuo evento è pronto per essere creato!",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyQ:
    "Posso creare un gruppo privato o unirsi a un gruppo in modo privato?",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyA:
    "Tutti i gruppi nei Luoghi e negli Eventi Verificati sono pubblici. Nonostante ciò, puoi cambiare il tuo profilo in privato, il che limita la tua visibilità nei gruppi solo ai tuoi amici e ai membri di quel gruppo specifico.",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyQ:
    "Posso creare un evento privato o partecipare a un evento in modo privato?",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyA:
    "A differenza dei gruppi nei Luoghi e negli Eventi Verificati, puoi creare o unirti a Eventi Personalizzati privati. Se sei il creatore di un evento personalizzato, puoi in qualsiasi momento cambiare l'evento da aperto a privato e viceversa.",
  AmIMakingAreservationWhenCreatingAGroupQ:
    "Sto facendo una prenotazione quando creo un gruppo?",
  AmIMakingAreservationWhenCreatingAGroupA:
    "Al momento, Join non effettua prenotazioni per i tavoli dei tuoi gruppi.",
  IAmHesitantToJoinQ:
    "Esito a unirmi e creare gruppi su Join, poiché altre persone sapranno della mia vita reale – vita sociale.",
  IAmHesitantToJoinA:
    "Comprendiamo profondamente la tua preoccupazione relativa alla privacy e facciamo del nostro meglio per soddisfare le tue esigenze in termini di privacy, ad esempio offrendoti la possibilità di limitare la visibilità del tuo profilo tramite le impostazioni dell'App o di creare eventi privati, mantenendo Join funzionale, essendo un'App che ha come obiettivo principale far incontrare le persone nella vita reale.",
  CanIInviteFriendsToSomeoneElsesEventQ:
    "Posso invitare amici a un evento di qualcun altro?",
  CanIInviteFriendsToSomeoneElsesEventA:
    "Sì, puoi. Solo se si tratta di un evento privato, il suo creatore riceverà una notifica della tua intenzione di invitare qualcuno al suo evento e dovrà approvarla affinché l'invito sia inviato. Negli eventi aperti, proprio come nei gruppi, qualsiasi membro può invitare liberamente i propri amici senza coinvolgere il creatore.",
  IHaveBlockedSomeoneOnJoinQ:
    "Ho bloccato qualcuno su Join. Possono vedere i gruppi e gli eventi a cui partecipo?",
  IHaveBlockedSomeoneOnJoinA:
    "Le persone che hai bloccato su Join non possono vedere se ti sei unito, hai creato o sei stato invitato a un gruppo o a un evento, a meno che non siano coinvolte anch'esse in quel gruppo o evento.",
  WhatAmISeeingInTheHotPageQ: "Cosa vedo nella pagina 'Hot'?",
  WhatAmISeeingInTheHotPageA:
    "Il contenuto di Join è composto da Gruppi ed Eventi Personalizzati. I gruppi possono essere creati sotto 'Luoghi' o sotto 'Eventi Verificati', mentre gli Eventi Personalizzati sono entità indipendenti create e personalizzate dagli stessi utenti. Abbiamo creato un algoritmo che filtra gruppi ed eventi personalizzati in base a quanto il tuo profilo corrisponde ai profili presenti, li mescola e li presenta di conseguenza nella pagina Hot.",
  WhyIsJoinAskingForAllThisProfileInfoQ:
    "Perché Join chiede tutte queste informazioni sul profilo?",
  WhyIsJoinAskingForAllThisProfileInfoA:
    "Rispettiamo la tua preoccupazione riguardo alla privacy del profilo, cercando di offrire ai nostri utenti il nostro unico scopo. Non è altro che portare le persone, come te, insieme nella vita reale. Per raggiungere correttamente questo obiettivo, abbiamo bisogno che ci racconti di te, cosa ti piace fare nella vita reale, dove studi o hai studiato, se sei uno studente e che tipo di persone preferisci con cui interagire.",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinQ:
    "Posso omettere di rivelare alcune informazioni sul profilo richieste da Join?",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinA:
    "Consigliamo generalmente ai nostri utenti di non saltare la presentazione corretta e adeguata di se stessi tramite i loro profili su Join, poiché ciò comprometterà la loro esperienza sulla nostra piattaforma. Tuttavia, in molti casi, puoi scegliere 'Nessuno' o 'Altro' come risposta, nel caso in cui non desideri divulgare le informazioni che ti stiamo chiedendo.",
  WhatISProfileStrengthQ: "Cos'è la 'Forza del Profilo'?",
  WhatISProfileStrengthA:
    "La Forza del Profilo indica quanto è adeguato e forte il tuo profilo su Join, basato sul suo stato attuale. Non indica se è completo o meno ed è visibile solo a te.",
  DiscoverTheMainIdea: "Scopri l'idea",
  SeeHowToInteract: "Scopri come interagire",
  MoreAboutEventCreation: "Maggiore informazione sulla creazione di eventi",
  ContactUsTitle: "Contattaci",
  ContactUsSubtitle:
    "Mettiti in contatto per segnalare eventuali problemi, per richieste commerciali o semplicemente per dire ciao!",
  SomethingAboutUs: "Qualcosa su di noi",
  SiteTitle: "Join | Gruppi, Eventi & Persone",
  AppDescription:
    "Join è un'app mobile che riguarda tutto il vero socializzare. Usala per unirti a gruppi ed eventi come feste e molto altro. Crea un account e conosci persone in un modo mai visto prima.",
  OpenInApp: "Apri nell'App",
};

export default it;
