import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./i18n-en"; 
import el from "./i18n-el"; 
import de from "./i18n-de"; 
import es from "./i18n-es"; 
import it from "./i18n-it"; 
import fr from "./i18n-fr"; 
import ru from "./i18n-ru"; 
import zh from "./i18n-zh"; 
import LanguageDetector from 'i18next-browser-languagedetector';

// Translation resources object
const resources = {
  en: { translation: en }, 
  el: { translation: el },
  de: { translation: de },
  es: { translation: es },
  fr: { translation: fr },
  it: { translation: it },
  ru: { translation: ru },
  zh: { translation: zh },
};

i18n.use(initReactI18next)
.use(LanguageDetector)
.init({
  resources,
  fallbackLng: "en",  // Fallback language
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;

