import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import PhotoAndText from "./PhotoAndText";
import PhotoPairAndText from "./PhotoPairAndText"; // Import the new widget
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import DetailsButton from "../../components/DetailsButton";

export default function SecondSection() {
  const { t, i18n } = useTranslation();

  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{ bgcolor: "#ADD8E6", paddingTop: 4, paddingBottom: 12 }}
        paddingInline={{ lg: 16, xs: 4 }}
      >
        <Typography
          variant="h2"
          component="h2"
          align="center"
          color="rgb(10, 61, 98)"
          fontSize={{ xs: 22, md: 32 }}
          fontFamily={
            i18n.language === "el" ? "Manrope Regular" : "Montserrat Regular"
          }
          paddingBottom={4}
        >
          {t("Features")}
        </Typography>

        {/* Divider line */}
        <Box
          sx={{
            width: "width",
            height: "3px",
            backgroundColor: "#3f6891", // Vibrant light blue
            margin: "0 auto",
            marginBottom: 6, // Space between the divider and the content below
            borderRadius: "10px", // Rounded edges
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect
            animation: "fadeIn 1s ease-in-out", // Animation effect
          }}
        ></Box>

        <Grid container justifyContent="center" alignItems="start" spacing={10}>
          {/* First set of images using the new PhotoPairAndText */}
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <PhotoPairAndText
              photo1="IMG_1308"
              photo1Alt="Join mobile app - homepage"
              photo1Title="Join Homepage"
              photo2="IMG_1310"
              photo2Alt="Join mobile app - groups page"
              photo2Title="Join Groups Page"
              texta={t("JoinGroupsOfHeader")}
              textb={t("JoinGroupsOfTextOne")}
              textc={t("JoinGroupsOfTextTwo")}
              rotation={-10}
              language={i18n.language}
            />
            <DetailsButton
              targetUrl="/aboutus/true"
              text={t("DiscoverTheMainIdea")}
            />
          </Grid>

          {/* Second image using the original PhotoAndText */}
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <PhotoAndText
              photoAlt="An event in the hot-page of Join mobile app"
              photoTitle="Join mobile app - event in hot page"
              photo="IMG_1488"
              texta={t("FriendsAndDatesHeader")}
              textb={t("FriendsAndDatesTextOne")}
              textc={t("FriendsAndDatesTextTwo")}
              language={i18n.language}
            />
            <DetailsButton
              targetUrl="/faq/interact"
              text={t("SeeHowToInteract")}
            />
          </Grid>

          {/* Third image using the original PhotoAndText */}
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <PhotoPairAndText
              photo1="IMG_1318"
              photo1Alt="Join mobile app - create group page"
              photo1Title="Join Group Creation Page"
              photo2="IMG_1317"
              photo2Alt="Join mobile app - create event page"
              photo2Title="Join - Event Creation Page"
              texta={t("CreateUniqueEventsHeader")}
              textb={t("CreateUniqueEventsTextOne")}
              textc={t("CreateUniqueEventsTextTwo")}
              language={i18n.language}
              rotation={10}
            />
            <DetailsButton
              targetUrl="/faq/events_more"
              text={t("MoreAboutEventCreation")}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
