import React from "react";
import FirstSection from "./FirstSection.js";
import SecondSection from "./SecondSection";

function AboutUsScreen() {
  return (
    <>
      <FirstSection />
      <SecondSection />
    </>
  );
}

export default AboutUsScreen;
