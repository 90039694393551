import React from "react";
import "../../assets/Legal.css";
import { useTranslation } from "react-i18next";
import PrivacyPolicyGreek from "./PrivacyPolicyGreek";
import PrivacyPolicy from "./PrivacyPolicy";

function PrivacyPolicyScreen() {
  const { i18n } = useTranslation();

  return (
    <div className="legal-container">
      {i18n.language === "el" ? <PrivacyPolicyGreek /> : <PrivacyPolicy />}
    </div>
  );
}

export default PrivacyPolicyScreen;
