import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

export const StyledTextField = styled(TextField)({
  backgroundColor: "#F0F0F0",
  borderRadius: 15,
  boxShadow: "none",
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      borderRadius: 15,
    },
    "&:hover fieldset": {
      borderColor: "#1EABF1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1EABF1",
    },
  },
});

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "myColor" && prop !== "hoverColor",
})(({ theme, myColor, hoverColor }) => ({
  color: "#ffffff",
  textTransform: "none",
  backgroundColor: myColor,
  boxShadow: "none",
  borderRadius: 13,
  "&:hover": {
    backgroundColor: hoverColor,
    boxShadow: "none",
  },
}));

export const StyledIconButton = styled(IconButton)({
  textTransform: "none",
  //color: '#B2B2B2',
  color: "#57606f",
  boxShadow: "none",
  borderRadius: 13,
  "&:hover": {
    color: "#5FC9F8",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});

export const StyledSelect = styled(Select)({
  backgroundColor: "#F0F0F0",
  borderRadius: 15,
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1EABF1",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1EABF1",
  },
});

export const StyledLink = styled(Link)({
  textTransform: "none",
  //color: '#B2B2B2',
  color: "#57606f",
  boxShadow: "none",
  borderRadius: 13,
  "&:hover": {
    color: "#5FC9F8",
    boxShadow: "none",
  },
});