import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Link as RouterLink } from "react-router-dom";
import FooterSocial from "./FooterSocial";
import { useTranslation } from "react-i18next";
import { StyledLink } from "./StyledComponents";

export default function FooterLinks() {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={{ xs: 3, sm: 8, md: 20 }}
      direction="row"
      justifyContent="center"
    >
      <Stack
        spacing={1}
        direction="column"
        alignItems={{ xs: "start", sm: "start" }}
      >
        <Box
          sx={{
            fontSize: { xs: 18, sm: 20 },
            fontFamily: "OpenSans Bold",
            marginBottom: 2,
            color: "#57606f",
          }}
        >
          {t("Legal")}
        </Box>
        <StyledLink
          component={RouterLink}
          to="/privacy_policy"
          underline="none"
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          {t("PrivacyPolicy")}
        </StyledLink>
        <StyledLink
          component={RouterLink}
          to="/terms"
          underline="none"
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          {t("Terms")}
        </StyledLink>
        <StyledLink
          component={RouterLink}
          to="/cookie_policy"
          underline="none"
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          {t("CookiePolicy")}
        </StyledLink>
      </Stack>
      <Stack spacing={1} direction="column" alignItems="center">
        <Box
          sx={{
            fontSize: { xs: 18, sm: 20 },
            fontFamily: "OpenSans Bold",
            color: "#57606f",
          }}
        >
          Social
        </Box>
        <FooterSocial />
        <Box
          sx={{
            fontSize: { xs: 12, sm: 16 },
            fontFamily: "OpenSans SemiBold",
            color: "#57606f",
            paddingTop: 1,
          }}
        >
          © 2024 Join Social App V0.8
        </Box>
      </Stack>
      <Stack spacing={1} direction="column" alignItems="start">
        <StyledLink
          component={RouterLink}
          to="/contact"
          underline="none"
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          {t("ContactUs")}
        </StyledLink>
        <StyledLink
          component={RouterLink}
          to="/faq"
          underline="none"
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          FAQs
        </StyledLink>
        <StyledLink
          component={RouterLink}
          to="/"
          underline="none"
          sx={{ fontSize: { xs: 14, sm: 16 } }}
        >
          {t("Download")}
        </StyledLink>
      </Stack>
    </Stack>
  );
}
