import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import { StyledIconButton } from "./StyledComponents";
import logo from "../assets/joinlogo.png";
import Box from "@mui/material/Box";
import {

  useLocation,
} from "react-router-dom";

export default function LanguageSelector() {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const lang = params.get("lang") || "en"; // Default to "en"
  console.log(lang)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectLanguage = (language) => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  return (
    <>
      <StyledIconButton
        aria-label="languages"
        id="language-button"
        onClick={handleClickOpen}
      >
        <LanguageIcon />
      </StyledIconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm" // Adjust width as needed
        fullWidth
        PaperProps={{
          style: {
            padding: "20px",
            maxWidth: "400px", // Limit maximum width
            margin: "0 auto", // Center the dialog
          },
        }}
      >
        {/* Top Bar with Logo and Close Icon */}
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <img
                src={logo}
                alt="Join Trademark - Mobile app for socializing, joining events, meeting people, and dating"
                title="Trademark of Join - A Mobile App for Real Socializing, Events, and Dating"
                style={{ height: 30 }}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "#808080", // Vibrant light blue
              margin: "0 auto",
              marginBottom: 2, // Space between the divider and the content below
              borderRadius: "10px", // Rounded edges
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect
              animation: "fadeIn 1s ease-in-out", // Animation effect
            }}
          ></Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("en")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "en" ? "bold" : "normal",
                      }}
                    >
                      English
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("el")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "el" ? "bold" : "normal",
                      }}
                    >
                      Ελληνικά
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("es")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "es" ? "bold" : "normal",
                      }}
                    >
                      Español
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("it")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "it" ? "bold" : "normal",
                      }}
                    >
                      Italiano
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("ru")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "ru" ? "bold" : "normal",
                      }}
                    >
                      Русский
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("de")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "de" ? "bold" : "normal",
                      }}
                    >
                      Deutsch
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("fr")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "fr" ? "bold" : "normal",
                      }}
                    >
                      Français
                    </div>
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton onClick={() => selectLanguage("zh")} fullWidth>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "0.9rem",
                        fontWeight:
                          currentLanguage === "zh" ? "bold" : "normal",
                      }}
                    >
                      中文
                    </div>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
