import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../../assets/App.css";

// Import local images
import image1 from '../../assets/IMG_9529.jpg';
import image2 from '../../assets/DIMITRISPHOTO.jpg';
// import image3 from '../../assets/IMG_1308.PNG';

// Component for rendering each item (image)
function Item({ item }) {
  return (
    <img className="slider-item" src={item.source} alt={item.description} />
  );
}

function FirstSection() {
  const sliderItems = [
    {
      source: image1,
      title: "George Bratsiotis, developer of Join and Dimitris Chatzichristofas, general manager.", 
      alt: "On the left side is George Bratsiotis, head of development for Join application. On the right side is Dimitris Chatzichristofas, general manager of Join.",
      description: "On the left side is George Bratsiotis, head of development for Join application. On the right side is Dimitris Chatzichristofas, general manager of Join.",
      id: "image-1",
    },
    {
      source: image2,
      title: "Dimitris Chatzichristofas, general manager and co-founder of Join", 
      alt: "Dimitris Chatzichristofas, general manager and co-founder of Join",
      description: "Dimitris Chatzichristofas, general manager and co-founder of Join",
      id: "image-3",
    },
    // {
    //   source: image3,
    //   title: "Chris [], back-end developer of Join", 
    //   alt: "Chris [], back-end developer of Join",
    //   description: "Chris [], back-end developer of Join",
    //   id: "image-2",
    // },
  ];



  return (
    <div style={{ marginTop: 90, marginBottom: 20 }}>
        <div className="carousel-container"> 
      <Carousel
        autoPlay={true}
        centerMode={true}
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        stopOnHover={true}
        useKeyboardArrows={true}
        swipeable={true}
        centerSlidePercentage={100}
        dynamicHeight={false}
        showThumbs={false}
        interval={10000}
      >
        {sliderItems.map((item, i) => (
          <Item key={item?.id || i} item={item} />
        ))}
      </Carousel>
      </div>
    </div>
  );
}

export default FirstSection;
