const en = {
  Slogan: "Real Socializing",
  AvailableIn: "Available in",
  AvailableNow: "Available now",
  Days: "DAYS",
  Hours: "HRS",
  Minutes: "MIN",
  Seconds: "SEC",
  Features: "Features",
  JoinGroupsOfHeader: "JOIN GROUPS OF INTERESTING PEOPLE",
  JoinGroupsOfTextOne: "Meeting people was never easier.",
  JoinGroupsOfTextTwo:
    "View groups of interesting people that will meet at a certain place, big event or user created event. Ask to join them or like so that they can respond by inviting you.",
  FriendsAndDatesHeader: "FRIENDS AND DATES THAT ARE RIGHT FOR YOU",
  FriendsAndDatesTextOne: "Dating or purely socializing, it’s your choice.",
  FriendsAndDatesTextTwo:
    "Don’t miss out on meeting people that match your type. People with matching traits and dating preferences will show up as a Social Match. We love connecting you with the right people for you!",
  CreateUniqueEventsHeader: "CREATE YOUR OWN GROUPS AND UNIQUE EVENTS",
  CreateUniqueEventsTextOne:
    "Fancy throwing a party or maybe playing football with your friends?",
  CreateUniqueEventsTextTwo:
    "Besides creating and joining groups in places and big events that we have listed, you can also create your own, custom events. Make it massive or keep it private, the choice is yours.",
  Legal: "Legal",
  PrivacyPolicy: "Privacy Policy",
  Terms: "Terms",
  CookiePolicy: "Cookie Policy",
  ContactUs: "Contact Us",
  Download: "Download!",
  AboutUs: "About Us",
  RealSocializing: "Real Socializing",
  WhyToGetJoinHeader: "Why to get Join?",
  WhyToGetJoinTextOne:
    "We doubt that you using even one application that truly promotes your social life. \nNo offense to big players like Instagram or Facebook: To date there are a few successful “social” – but in reality, kind of do-it-all platforms and for good reason. But we offer a dimension to socializing that was missing from the until-recently existing social Apps.",
  WhyToGetJoinTextTwo:
    "We proudly claim to be the first actual social App, because we only do that one thing as best as we can: We bring people together in real life.\n\nUsing Join means gaining access to groups of people who have scheduled to visit hospitality and leisure businesses, like bars, night clubs, cafeterias, music events and more. Available are also parties, sports games and other events for you to join.\nNext to helping you plan fun stuff with your current friends, we have also made giving you the opportunity of making new quality friends and dates our first priorities. Forget about feeling awkward, as you can now comfortably meet the right people with the minimum effort. We can’t really explain how in a few sentences, but we made sure to break the ice once and for all. Join is far from another awkward dating platform people are embarrassed to use.\n\nDear social App user, even though Join is the breath of fresh air you needed, we also absolutely need you too. We hope that you will enjoy your experience on our App.",
  OurHistoryHeader: "Our History - Join & The Team",
  OurHistoryTextOne:
    "Covid-19 turned the world upside down. What quarantine did to Greece, in social aspect terms, was that it removed socializing from people’s lives and crushed its home, the Hospitality Industry. That’s where Join comes into play.",
  OurHistoryTextTwo:
    "Truth is that our human relationships weren’t the same after the pandemic. Some came out weaker and some stronger, while quite a few were abandoned altogether\n\n-People distinguished who really matters for them-\n\nITALIC“That said, on a boring evening of 2021 quarantine, I set my mind onto somehow connecting the back-then missed bars, clubs and cafeterias, to name but a few, with the people’s need of making real relationships. The result is today called Join, a mobile App available on App Store and Google Play and I am Dimitris Chatzichristofas, its co-founder.\n\nITALICCreating Join was not a smooth process and that’s why I’m grateful to have had two great developers and co-founders, George Bratsiotis and Chris , who developed the app from scratch while I did most of the business tasks. We still have a lot of work to do and we’re excited about what’s to come.“",
  FullName: "Full Name",
  Subject: "Subject",
  AccountOrPrivacyIssue: "Account / Privacy Issue",
  ReportInappropriateBehaviour: "Report Inappropriate Behaviour",
  UserComplaint: "User Complaint",
  BusinessEnquiry: "Business Enquiry",
  Other: "Other",
  Message: "Message",
  SubmitButton: "Submit",
  INeedHelpCreatingMyGroupQ: "I need help creating my group.",
  INeedHelpCreatingMyGroupQASectionOne:
    "First, know that being your own group’s creator means you have absolute control over it, so there is no need for stress, even if it’s the first one you’re creating. Here’s a simple guide.",
  DateAndTimeDescrGroups:
    "Here you are choosing the date and meeting time of your group. Don’t get confused with a.m. times: For example, Sunday, 00:45 may still be Saturday evening in your mind, but on Join it’s Sunday morning and that’s how you need to list it.",
  InviteYourFriendsDescrGroups:
    "This is the step where you initially invite your friends (don’t worry, you can invite more afterward). Invited members will receive an invitation notification from you, which they must affirmatively answer if they are to join the group. You can create a group without inviting anybody and have people request to join you.",
  MakeAStatement: "Make a Statement",
  MakeAStatementDescrGroups:
    "This is your chance to add an element of personal touch to your group. This can be something in the likes of “We are celebrating my birthday” or “Girls wear a white dress”.",
  YourGroupIsReadyForCreation: "Your group is now ready for creation!",
  INeedHelpCreatingMyEvent: "I need help creating my event.",
  INeedHelpCreatingMyEventASectionOne:
    "First, know that being your own event’s creator means you have absolute control over it, so there is no need for stress, even if it’s the first one you’re creating. Here’s a simple guide.",
  EventTitle: "Event Title",
  EventTitleDescrEvents:
    "This must be a brief description of your event. Something like “5x5 football in Chalandri”, “Secret location beach party”, or “Chill meetup in Agia Paraskevi square”.",
  DescribeYourEvent: "Describe your Event",
  DescribeYourEventDescrEvents:
    "Now it’s your chance to describe your event. Include any detail you would have wanted to see at an event like yours, created by someone else. Anything important, you should include here. Trust us, if you are analytical, then more people and more suitable people will show interest in your event.",
  PrivateEvent: "Private Event",
  PrivateEventDescrEvents:
    "All custom events are open by default, meaning that all users of Join can view and interact with your event, except people you’ve blocked. If you switch your event to private, then access will be limited to only your friends and people you invite. You can alter your event privacy even after its creation.",
  DateAndTime: "Date and Time",
  DateAndTimeDescrEvents:
    "Here you are choosing the date and time your event will begin at. Don’t get confused with a.m. times: For example, Sunday, 00:45 may still be Saturday evening in your mind, but on Join it’s Sunday morning and that’s how you need to list it.",
  Category: "Category",
  CategoryDescrEvents:
    "We concluded that your event will most likely fall under one of these categories: Sports, Party, Travel, or Meetup. If you cannot clearly see your event belonging in any of these categories, please pick “meetup”. After all, an event can’t not be a meetup.",
  InviteYourFriends: "Invite your Friends",
  InviteYourFriendsDescrEvents:
    "This is the step where you initially invite your friends (don’t worry, you can invite more afterwards). Invited members will receive an invitation notification from you, which they must affirmatively answer if they are to join the event. You can create an event without inviting anybody and have people request to join you.",
  YourCustomEventIsReadyForCreation: "Your event is now ready for creation!",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyQ:
    "Can I create a private group or join a group privately?",
  CanICreateAPrivateGroupOrJoinAGroupPrivatelyA:
    "All groups at Places and Verified Events are public. Despite that, you may change your profile to private, which limits your appearance in the groups to only your friends and joined members of that specific group.",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyQ:
    "Can I create a private event or join an event privately?",
  CanICreateAPrivateEventOrJoinAnEventPrivatelyA:
    "In contrast to groups at Places and Verified Events, you can create or join private Custom Events. If you are the creator of a custom event, you may at all times switch your event from open to private and vice versa.",
  AmIMakingAreservationWhenCreatingAGroupQ:
    "Am I making a reservation when creating a group?",
  AmIMakingAreservationWhenCreatingAGroupA:
    "At the moment, Join does not make table reservations for your groups.",
  IAmHesitantToJoinQ:
    "I am hesitant to join and create groups on Join, as other people will then know about my real life – social life.",
  IAmHesitantToJoinA:
    "We deeply understand your concern related to your privacy and try our best to meet your privacy needs, such as by offering you the ability to restrict your profile exposure via the App’s settings or to create private events, while keeping Join functional, being an App that has bringing people together in real life at its core.",
  CanIInviteFriendsToSomeoneElsesEventQ:
    "Can I invite friends to someone else’s event?",
  CanIInviteFriendsToSomeoneElsesEventA:
    "Yes, you can. Only if it’s a private event, its creator will receive a notification of your intent to invite someone to their event and must approve it for the invitation to go through. In open events, just like in groups, any joined member can freely invite their friends without engaging the creator.",
  IHaveBlockedSomeoneOnJoinQ:
    "I have blocked someone on Join. Do they get to see the groups and events that I join?",
  IHaveBlockedSomeoneOnJoinA:
    "People that you’ve blocked on Join cannot see if you’ve joined, created, or gotten invited to a group or an event, unless they relate to that group or event as well.",
  WhatAmISeeingInTheHotPageQ: "What am I seeing in the “Hot” Page?",
  WhatAmISeeingInTheHotPageA:
    "Join’s content is comprised of Groups and Custom Events. Groups can be created under “Places” or under “Verified Events”, whereas Custom Events are standalone entities created and customized by the users themselves. We have created an algorithm that filters groups and custom events according to how well your profile matches with the profiles in them, mixes and presents them accordingly in the Hot Page.",
  WhyIsJoinAskingForAllThisProfileInfoQ:
    "Why is Join asking for all this profile info?",
  WhyIsJoinAskingForAllThisProfileInfoA:
    "We respect your concern regarding your profile privacy, while striving to deliver our sole purpose to our users. That is no other than to bring you, as people, together in real life. To correctly achieve this, we need you to tell us about you, what you like doing in real life, where you are studying or have studied, if you are a student and what type of people you prefer interacting with.",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinQ:
    "Can I omit to disclose some profile info asked by Join?",
  CanIOmitToDiscloseSomeProfileInfoAskedByJoinA:
    "We generally advise our users not to skip on correctly and adequately presenting themselves via their profiles on Join, as this will harm their experience on our platform. Despite that, in many instances, you can choose “None” or “Other” as an answer, in case you don’t want to disclose the information we are asking about you.",
  WhatISProfileStrengthQ: "What is “Profile Strength”?",
  WhatISProfileStrengthA:
    "Profile Strength depicts how adequate and strong your profile will be on Join,  based on its current state. It does not depict whether it is complete or not and it is only visible to you.",
  DiscoverTheMainIdea: "Discover the idea",
  SeeHowToInteract: "See how to interact",
  MoreAboutEventCreation: "More about events",
  ContactUsTitle: "Contact us",
  ContactUsSubtitle:
    "Get in touch to report possible issues, for business enquiries or to simply say hi!",
  SomethingAboutUs: "Something about us",
  SiteTitle: "Join | Groups, Events & People",
  AppDescription:
    "Join is a mobile app that is all about real socializing. Use it to join groups & events like parties and many more. Create an account and date people in a way like never before.",
  OpenInApp: "Open in App",
};

export default en;
