function getFaqs(language, category) {
  if (!category) return faqs[language];

  const allFaqs = faqs[language];
  const filteredFaqs = allFaqs.filter((f) => f.categories.includes(category));
  return filteredFaqs;
}

const faqs = {
  en: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "I need help creating my group",
      answer: `
First, know that being your own group’s creator means you have absolute control over it, so there is no need for stress, even if it’s the first one you’re creating. Here’s a simple guide.

#### Date and Time
Here you are choosing the date and meeting time of your group. Don’t get confused with a.m. times: For example, Sunday, 00:45 may still be Saturday evening in your mind, but on Join it’s Sunday morning and that’s how you need to list it. 

#### Invite your Friends
This is the step where you initially invite your friends (don’t worry, you can invite more afterward). Invited members will receive an invitation notification from you, which they must affirmatively answer if they are to join the group. You can create a group without inviting anybody and have people request to join you. 

#### Make a Statement
This is your chance to add an element of personal touch to your group. This can be something in the likes of “We are celebrating my birthday” or “Girls wear a white dress”.

Your group is now ready for creation!
      `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "I need help creating my event",
      answer: `
First, know that being your own event’s creator means you have absolute control over it, so there is no need for stress, even if it’s the first one you’re creating. Here’s a simple guide.

#### Event Title
This must be a brief description of your event. Something like “5x5 football in Chalandri”, “Secret location beach party”, or “Chill meetup in Agia Paraskevi square”.


#### Describe your Event
Now it’s your chance to describe your event. Include any detail you would have wanted to see at an event like yours, created by someone else. Anything important, you should include here. Trust us, if you are analytical, then more people and more suitable people will show interest in your event.

#### Private Event
All custom events are open by default, meaning that all users of Join can view and interact with your event, except people you’ve blocked. If you switch your event to private, then access will be limited to only your friends and people you invite. You can alter your event privacy even after its creation.

#### Date and Time
Here you are choosing the date and time your event will begin at. Don’t get confused with a.m. times: For example, Sunday, 00:45 may still be Saturday evening in your mind, but on Join it’s Sunday morning and that’s how you need to list it. 

#### Category
We concluded that your event will most likely fall under one of these categories: Sports, Party, Travel, or Meetup. If you cannot clearly see your event belonging in any of these categories, please pick “meetup”. After all, an event can’t not be a meetup.

#### Invite your Friends
This is the step where you initially invite your friends (don’t worry, you can invite more afterwards). Invited members will receive an invitation notification from you, which they must affirmatively answer if they are to join the event. You can create an event without inviting anybody and have people request to join you. 

Your custom event is now ready for creation !`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question: "Can I create a private group or join a group privately?",
      answer: `
All groups at Places and Verified Events are public. Despite that, you may change your profile to private, which limits your appearance in the groups to only your friends and joined members of that specific group.`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question: "Can I create a private event or join an event privately?",
      answer: `
In contrast to groups at Places and Verified Events, you can create or join private Custom Events. If you are the creator of a custom event, you may at all times switch your event from open to private and vice versa.`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Am I making a reservation when creating a group?",
      answer: `
At the moment, Join does not make table reservations for your groups.`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "I am hesitant to join and create groups on Join, as other people will then know about my real life – social life",
      answer: `
We deeply understand your concern related to your privacy and try our best to meet your privacy needs, such as by offering you the ability to restrict your profile exposure via the App’s settings or to create private events, while keeping Join functional, being an App that has bringing people together in real life at its core.
  `,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Can I invite friends to someone else’s event?",
      answer: `
Yes, you can. Only if it’s a private event, its creator will receive a notification of your intent to invite someone to their event and must approve it for the invitation to go through. In open events, just like in groups, any joined member can freely invite their friends without engaging the creator.`,
    },
    {
      id: 8,
      categories: [],
      question:
        "I have blocked someone on Join. Do they get to see the groups and events that I join?",
      answer: `
People that you’ve blocked on Join cannot see if you’ve joined, created, or gotten invited to a group or an event, unless they relate to that group or event as well. `,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "What am I seeing in the 'Hot Page'?",
      answer: `
Join’s content is comprised of Groups and Custom Events. Groups can be created under “Places” or under “Verified Events”, whereas Custom Events are standalone entities created and customized by the users themselves. We have created an algorithm that filters groups and custom events according to how well your profile matches with the profiles in them, mixes and presents them accordingly in the Hot Page.`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Why is Join asking for all this profile info?",
      answer: `
We respect your concern regarding your profile privacy, while striving to deliver our sole purpose to our users. That is no other than to bring you, as people, together in real life. To correctly achieve this, we need you to tell us about you, what you like doing in real life, where you are studying or have studied, if you are a student and what type of people you prefer interacting with. `,
    },
    {
      id: 11,
      categories: ["profile"],
      question: "Can I omit to disclose some profile info asked by Join?",
      answer: `
We generally advise our users not to skip on correctly and adequately presenting themselves via their profiles on Join, as this will harm their experience on our platform. Despite that, in many instances, you can choose “None” or “Other” as an answer, in case you don’t want to disclose the information we are asking about you.`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "What is 'Profile Strength'?",
      answer: `
Profile Strength depicts how adequate and strong your profile will be on Join,  based on its current state. It does not depict whether it is complete or not and it is only visible to you.`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "What are these two options?",
      answer: `
At this stage, you are seeing two options.

#### The first one is to create your Custom Event. Click on the -Custom Event- button and create your very own event, be it a party, a sports event, a meetup, or even a trip!.

#### The second option is for you to swipe down below, between Places & Verified Events until you find what you're looking to create your Group in.`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "What is the difference between a 'Custom Event' and a 'Group'?",
      answer: `
A Custom Event is an event created from a user -another member of Join just like you- unlike Verified Events which are simillar to Places, in that they are uploaded by the Join team.

#### Custom Events have their own entity and presence, whereas Groups get listed only under a Place or a Verified Event.`,
    },
  ],
  el: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "Χρειάζομαι βοήθεια για τη δημιουργία του group μου",
      answer: `
Κατ' αρχάς, να ξέρεις ότι όντας ο δημιουργός του δικού σου group, σημαίνει ότι έχεις τον απόλυτο έλεγχο του, οπότε δεν υπάρχει λόγος για άγχος, ακόμη και αν είναι το πρώτο που δημιουργείς. Ακολουθεί ένας απλός οδηγός.

#### Ημερομηνία και Ώρα
Εδώ επιλέγεις την ημερομηνία και ώρα συνάντησης του group. Μην μπερδευτείς με τις πρωινές ώρες: Για παράδειγμα, η Κυριακή, 00:45 μπορεί να είναι ακόμα Σάββατο βράδυ στο μυαλό σου, αλλά στο Join είναι Κυριακή πρωί και έτσι πρέπει να το καταχωρήσεις.

#### Προσκάλεσε Φίλους
Αυτό είναι το βήμα στο οποίο προσκαλείς τους φίλους σου (μην ανησυχείς, μπορείς να προσκαλέσεις κι άλλους στη συνέχεια). Τα προσκεκλημένα μέλη θα λάβουν μια ειδοποίηση πρόσκλησης από εσένα, στην οποία θα πρέπει να απαντήσουν καταφατικά αν θέλουν να γίνουν μέλη του group. Μπορείς να δημιουργήσεις ένα group χωρίς να προσκαλέσεις κανέναν περιμένοντας κόσμο να σου στείλει join request.

#### Κάνε μία Δήλωση
Αυτή είναι η ευκαιρία σου να προσθέσεις ένα στοιχείο προσωπικής πινελιάς. Αυτό μπορεί να είναι κάτι σαν "Γιορτάζουμε τα γενέθλιά μου" ή "Κορίτσια φορέστε λευκό φόρεμα".

Το group σου είναι τώρα έτοιμo για δημιουργία!
      `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "Χρειάζομαι βοήθεια για τη δημιουργία του event μου.",
      answer: `
Κατ' αρχάς, να ξέρεις ότι όντας ο δημιουργός του δικού σου event σημαίνει ότι έχεις τον απόλυτο έλεγχο του, οπότε δεν υπάρχει λόγος να αγχώνεσαι, ακόμη και αν είναι το πρώτο event που δημιουργείς. Ακολουθεί ένας απλός οδηγός.",

#### Τίτλος του Event
Αυτή πρέπει να είναι μια σύντομη περιγραφή του event σου. Κάτι σαν "Ποδόσφαιρο 5χ5 στο Χαλάνδρι", "Secret location beach party", ή "Άραγμα στην πλατεία της Αγίας Παρασκευής".


#### Περιέγραψε το Event
Τώρα είναι η ευκαιρία να περιγράψεις αναλυτικά το event σου. Να συμπεριλάβεις κάθε λεπτομέρεια που θα ήθελες να δεις σε ένα event σαν το δικό σου, αναρτημένο από κάποιον άλλον. Οτιδήποτε σημαντικό, θα πρέπει να το συμπεριλάβεις εδώ. Πίστεψέ μας, αν είσαι αναλυτικός, τότε περισσότεροι και πιο κατάλληλοι άνθρωποι θα δείξουν ενδιαφέρον για το event σου.

#### Ιδιωτικό Event
Όλα τα εξατομικευμένα events είναι από προεπιλογή ανοιχτά, πράγμα που σημαίνει ότι όλοι οι χρήστες του Join μπορούν να δουν και να αλληλεπιδράσουν με το event σου, εκτός από τα άτομα που έχεις μπλοκάρει. Αν αλλάξεις το event σου σε ιδιωτικό, τότε η πρόσβαση θα περιοριστεί μόνο στους φίλους σου και στα άτομα που προσκαλείς. Μπορείς να αλλάξεις την ιδιωτικότητα του event σου ακόμα και μετά τη δημιουργία του.

#### Ημερομηνία και ώρα
Εδώ επιλέγεις την ημερομηνία και ώρα που ξεκινάει το event σου. Μην μπερδευτείς με τις πρωινές ώρες: Για παράδειγμα, η Κυριακή, 00:45 μπορεί να είναι ακόμα Σάββατο βράδυ στο μυαλό σου, αλλά στο Join είναι Κυριακή πρωί και έτσι πρέπει να το καταχωρήσεις.

#### Κατηγορία
Καταλήξαμε στο ότι το event σου πιθανότατα θα ανήκει σε μία από αυτές τις κατηγορίες: Αθλήματα, Πάρτι, Ταξίδια ή Συναντήσεις. Εάν δεν μπορείς να διακρίνεις ξεκάθαρα την κατηγορία του event σου, παρακαλούμε να επιλέξεις την «Συνάντηση». Όπως και να έχει, το event σου δεν γίνεται να μην είναι μια συνάντηση.

#### Προσκάλεσε Φίλους
Εδώ επιλέγεις την ημερομηνία και ώρα που ξεκινάει το event σου. Μην μπερδευτείς με τις πρωινές ώρες: Για παράδειγμα, η Κυριακή, 00:45 μπορεί να είναι ακόμα Σάββατο βράδυ στο μυαλό σου, αλλά στο Join είναι Κυριακή πρωί και έτσι πρέπει να το καταχωρήσεις.

Το event σου είναι έτοιμο για δημιουργία!`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "Μπορώ να δημιουργήσω ένα ιδιωτικό group ή να κάνω join ένα group ιδιωτικά;",
      answer: `
Όλα τα group στα Μαγαζιά και στα Επιλεγμένα Events είναι δημόσια. Παρόλα αυτά, μπορείς να αλλάξεις το προφίλ σου σε ιδιωτικό, πράγμα που περιορίζει την εμφάνισή σου στις ομάδες μόνο στους φίλους σου και στα ενταγμένα μέλη της συγκεκριμένης ομάδας.`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "Μπορώ να δημιουργήσω ένα ιδιωτικό event η να κάνω join ένα event ιδιωτικά;",
      answer: `
Σε αντίθεση με τα groups στα Μαγαζιά και τα Επιλεγμένα Events, μπορείς να δημιουργήσεις ή να ενταχθείς σε ιδιωτικά Εξατομικευμένα Εvents. Εάν είσαι ο δημιουργός ενός εξατομικευμένου event, μπορείς ανά πάσα στιγμή να το αλλάξεις από ανοιχτό σε ιδιωτικό και αντίστροφα.`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Κάνω κράτηση όταν δημιουργώ ένα group;",
      answer: `
Προς το παρόν, το Join δεν κάνει κρατήσεις τραπεζιών για τα group σου.`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "Διστάζω να κάνω join και να δημιουργήσω groups στο Join, καθώς οι άλλοι άνθρωποι θα γνωρίζουν τότε για την πραγματική, κοινωνική μου ζωή",
      answer: `
Κατανοούμε βαθύτατα την ανησυχία σου σχετικά με την ιδιωτική σου ζωή και προσπαθούμε να κάνουμε ό,τι καλύτερο μπορούμε για να ικανοποιήσουμε τις ανάγκες σου σε θέματα ιδιωτικότητας, όπως προσφέροντάς σου τη δυνατότητα να περιορίσεις την έκθεση του προφίλ σου μέσω των ρυθμίσεων της εφαρμογής ή να δημιουργήσεις ιδιωτικά Events, διατηρώντας παράλληλα το Join λειτουργικό, καθώς πρόκειται για μια Εφαρμογή που έχει στον πυρήνα της το να φέρνει τους ανθρώπους κοντά στην πραγματική ζωή.
  `,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Μπορώ να προσκαλέσω φίλους μου στο event κάποιου άλλου;",
      answer: `
Ναι, μπορείς. Μόνο αν πρόκειται για ιδιωτικό event, ο δημιουργός του θα λάβει ειδοποίηση για την πρόθεσή σου να προσκαλέσεις κάποιον στο event του και θα πρέπει να το εγκρίνει για να περάσει η πρόσκληση. Στα ανοιχτά events, όπως και στα groups, κάθε μέλος που έχει ενταχθεί, μπορεί ελεύθερα να προσκαλέσει τους φίλους του χωρίς να εμπλέξει κάπως τον δημιουργό.`,
    },
    {
      id: 8,
      categories: [],
      question:
        "Έχω μπλοκάρει κάποιον στο Join. Μπορεί να δει τα groups και τα events στα οποία συμμετέχω;",
      answer: `
Τα άτομα που έχετε μπλοκάρει στο Join δεν μπορούν να δουν αν έχετε συμμετάσχει, δημιουργήσει ή προσκληθεί σε ένα group ή ένα event, εκτός αν σχετίζονται και αυτά με το εν λόγω group ή event.`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "Τι βλέπω στην 'Hot Page';",
      answer: `
Το περιεχόμενο του Join αποτελείται από Groups και Εξατομικευμένα Events. Τα Groups μπορούν να δημιουργηθούν στο πλαίσιο των "Μαγαζιών" ή στο πλαίσιο των "Επιλεγμένων Events", ενώ τα εξατομικευμένα Events είναι αυτόνομες οντότητες που δημιουργούνται και προσαρμόζονται από τους ίδιους τους χρήστες. Έχουμε δημιουργήσει έναν αλγόριθμο που φιλτράρει τα Groups και τα εξατομικευμένα events ανάλογα με το πόσο καλά ταιριάζει το προφίλ σου με τα προφίλ σε αυτά, τα αναμειγνύει και τα παρουσιάζει αναλόγως στη Hot Page.`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Γιατί το Join ζητάει όλες αυτές τις πληροφορίες προφίλ;",
      answer: `
Σεβόμαστε την ανησυχία σου σχετικά με το απόρρητο του προφίλ σου, ενώ παράλληλα προσπαθούμε να προσφέρουμε τον μοναδικό μας σκοπό στους χρήστες μας. Αυτός δεν είναι άλλος από το να σας φέρουμε, ως ανθρώπους, κοντά στην πραγματική ζωή. Για να το πετύχουμε σωστά αυτό, πρέπει να μας πεις για σένα, τι σου αρέσει να κάνεις στην πραγματική ζωή, πού σπουδάζεις ή έχεις σπουδάσει, αν είσαι φοιτητής και με τι τύπου ανθρώπους προτιμάς να συναναστρέφεσαι.`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "Μπορώ να παραλείψω να αποκαλύψω κάποιες πληροφορίες προφίλ που ζητούνται από το Join;",
      answer: `
      Γενικά συμβουλεύουμε τους χρήστες μας να μην παραλείπουν να παρουσιάζουν σωστά και επαρκώς τον εαυτό τους μέσω του προφίλ τους στο Join, καθώς αυτό θα βλάψει την εμπειρία τους στην πλατφόρμα. Παρά ταύτα, σε πολλές περιπτώσεις, μπορείς να επιλέξεις ως απάντηση το «Κανένα» ή το «Άλλο», σε περίπτωση που δεν θέλεις να αποκαλύψεις τις πληροφορίες που σου ζητάμε.`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "Τι είναι το 'Profile Strength';",
      answer: `
Το Profile Strength απεικονίζει πόσο επαρκές και ισχυρό θα είναι το προφίλ σου στο Join, με βάση την τρέχουσα κατάστασή του. Δεν απεικονίζει αν είναι πλήρες ή όχι και είναι ορατό μόνο σε εσένα.`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "Τι είναι αυτές οι δύο επιλογές;",
      answer: `
Σε αυτό το στάδιο, βλέπεις δύο επιλογές.

#### Η πρώτη επιλογή είναι να δημιουργήσεις το δικο σου 'Custom Event'. Κάνε κλικ στο κουμπί -Το δικό σου Event-, και δημιουρήσε το event σου που μπορέι να είναι ένα πάρτυ, ένα αθλητικό event, μία συνάντηση ή ακόμα και ένα ταξίδι.

#### Η δεύτερη επιλογή είναι να σύρεις μεταξύ των Μαγαζιών και των Επιλεγμένων Events, μέχρι να βρεις αυτό στο οποίο θέλεις να δημιουργήσεις το Group σου.`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "Ποία ειναι η διαφορά μεταξύ ενός 'Εξατομικευμένου Event' και ενός 'Group'",
      answer: `
Ένα Εξατομικευμένο Event είναι ένα Event που δημιουργήθηκε από έναν χρήστη -ένα άλλο μέλος του Join όπως εσύ- σε αντίθεση με τα Επιλεγμένα Events, που είναι παρόμοια με τα Μαγαζία, καθώς αναρτώνται από την ομάδα του Join.

#### Τα Εξατομικευμένα Events έχουν τη δική τους οντότητα και παρουσία, ενώ τα Groups εμφανίζονται μόνο κάτω από ένα Μαγαζί ή ένα Επιλεγμένο Event.`,
    },
  ],
  de: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "Ich brauche Hilfe bei der Erstellung meiner Gruppe",
      answer: `
Zuerst sei dir bewusst, dass du als Ersteller deiner eigenen Gruppe die volle Kontrolle darüber hast, sodass es keinen Grund zur Stress gibt, selbst wenn es die erste ist, die du erstellst. Hier ist eine einfache Anleitung.

#### Datum und Uhrzeit
Hier wählst du das Datum und die Uhrzeit für dein Gruppentreffen. Lass dich nicht von a.m. Zeiten verwirren: Zum Beispiel kann Sonntag, 00:45 in deinem Kopf noch Samstagabend sein, aber bei Join ist es Sonntagmorgen, und so musst du es auflisten.

#### Lade deine Freunde ein
Dies ist der Schritt, in dem du zunächst deine Freunde einlädst (keine Sorge, du kannst später noch mehr einladen). Eingeladene Mitglieder erhalten eine Einladung von dir, die sie positiv beantworten müssen, um der Gruppe beizutreten. Du kannst eine Gruppe erstellen, ohne jemanden einzuladen, und Menschen können dich um einen Beitritt bitten.

#### Mach ein Statement
Dies ist deine Chance, deiner Gruppe eine persönliche Note zu verleihen. Das kann etwas sein wie „Wir feiern meinen Geburtstag“ oder „Mädchen tragen ein weißes Kleid“.

Deine Gruppe ist jetzt bereit zur Erstellung!
      `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "Ich brauche Hilfe bei der Erstellung meines Events",
      answer: `
Zuerst sei dir bewusst, dass du als Ersteller deines eigenen Events die volle Kontrolle darüber hast, sodass es keinen Grund zur Stress gibt, selbst wenn es das erste ist, das du erstellst. Hier ist eine einfache Anleitung.

#### Eventtitel
Dies muss eine kurze Beschreibung deines Events sein. Etwas wie „5x5 Fußball in Chalandri“, „Geheime Strandparty“ oder „Chill-Treffen am Agia Paraskevi Platz“.

#### Beschreibe dein Event
Jetzt ist es deine Chance, dein Event zu beschreiben. Füge alle Details hinzu, die du bei einem Event wie deinem, das von jemand anderem erstellt wurde, sehen möchtest. Alles Wichtige solltest du hier einfügen. Vertrau uns, wenn du analytisch bist, werden mehr Menschen und passendere Menschen Interesse an deinem Event zeigen.

#### Privates Event
Alle benutzerdefinierten Events sind standardmäßig öffentlich, was bedeutet, dass alle Benutzer von Join dein Event sehen und damit interagieren können, außer Menschen, die du blockiert hast. Wenn du dein Event auf privat umschaltest, wird der Zugang nur auf deine Freunde und eingeladene Personen beschränkt. Du kannst die Privatsphäre deines Events sogar nach seiner Erstellung ändern.

#### Datum und Uhrzeit
Hier wählst du das Datum und die Uhrzeit, zu der dein Event beginnen wird. Lass dich nicht von a.m. Zeiten verwirren: Zum Beispiel kann Sonntag, 00:45 in deinem Kopf noch Samstagabend sein, aber bei Join ist es Sonntagmorgen, und so musst du es auflisten.

#### Kategorie
Wir haben festgestellt, dass dein Event höchstwahrscheinlich in eine dieser Kategorien fällt: Sport, Party, Reisen oder Treffen. Wenn du nicht klar sehen kannst, dass dein Event in eine dieser Kategorien gehört, wähle bitte „Treffen“. Schließlich kann ein Event nicht kein Treffen sein.

#### Lade deine Freunde ein
Dies ist der Schritt, in dem du zunächst deine Freunde einlädst (keine Sorge, du kannst später noch mehr einladen). Eingeladene Mitglieder erhalten eine Einladung von dir, die sie positiv beantworten müssen, um dem Event beizutreten. Du kannst ein Event erstellen, ohne jemanden einzuladen, und Menschen können dich um einen Beitritt bitten.

Dein benutzerdefiniertes Event ist jetzt bereit zur Erstellung!
`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "Kann ich eine private Gruppe erstellen oder privat einer Gruppe beitreten?",
      answer: `
Alle Gruppen bei Places und Verified Events sind öffentlich. Dennoch kannst du dein Profil auf privat einstellen, was deine Sichtbarkeit in den Gruppen auf nur deine Freunde und die Mitglieder dieser speziellen Gruppe beschränkt.
`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "Kann ich ein privates Event erstellen oder privat an einem Event teilnehmen?",
      answer: `
Im Gegensatz zu Gruppen bei Places und Verified Events kannst du private benutzerdefinierte Events erstellen oder daran teilnehmen. Wenn du der Ersteller eines benutzerdefinierten Events bist, kannst du dein Event jederzeit von öffentlich auf privat und umgekehrt umschalten.
`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Mache ich eine Reservierung, wenn ich eine Gruppe erstelle?",
      answer: `
Momentan nimmt Join keine Tischreservierungen für deine Gruppen vor.
`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "Ich zögere, Gruppen auf Join beizutreten und zu erstellen, da andere Leute dann von meinem echten Leben – meinem sozialen Leben – erfahren werden",
      answer: `
Wir verstehen dein Anliegen bezüglich deiner Privatsphäre sehr gut und versuchen unser Bestes, um deine Datenschutzbedürfnisse zu erfüllen, indem wir dir die Möglichkeit bieten, deine Profilansicht über die App-Einstellungen einzuschränken oder private Events zu erstellen, während wir Join funktional halten, als App, die das Zusammenbringen von Menschen im echten Leben im Kern hat.
  `,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Kann ich Freunde zu einem Event von jemand anderem einladen?",
      answer: `
Ja, das kannst du. Nur wenn es ein privates Event ist, erhält der Ersteller eine Benachrichtigung über deine Absicht, jemanden zu seinem Event einzuladen, und muss dies genehmigen, damit die Einladung zustande kommt. Bei offenen Events, genau wie bei Gruppen, kann jedes Mitglied, das beigetreten ist, seine Freunde ohne Einbeziehung des Erstellers einladen.
`,
    },
    {
      id: 8,
      categories: [],
      question:
        "Ich habe jemanden auf Join blockiert. Kann er die Gruppen und Events sehen, denen ich beitrete?",
      answer: `
Personen, die du auf Join blockiert hast, können nicht sehen, ob du einer Gruppe oder einem Event beigetreten bist, es erstellt hast oder eingeladen wurdest, es sei denn, sie haben ebenfalls mit dieser Gruppe oder diesem Event zu tun.
`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "Was sehe ich auf der 'Hot Page'?",
      answer: `
Der Inhalt von Join besteht aus Gruppen und benutzerdefinierten Events. Gruppen können unter „Places“ oder „Verified Events“ erstellt werden, während benutzerdefinierte Events eigenständige Entitäten sind, die von den Benutzern selbst erstellt und angepasst werden. Wir haben einen Algorithmus erstellt, der Gruppen und benutzerdefinierte Events filtert, basierend darauf, wie gut dein Profil mit den Profilen darin übereinstimmt, und sie entsprechend auf der Hot Page mischt und präsentiert.
`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Warum fragt Join nach all diesen Profilinformationen?",
      answer: `
Wir respektieren dein Anliegen bezüglich der Privatsphäre deines Profils, während wir bestrebt sind, unser Hauptziel für unsere Benutzer zu erfüllen. Das ist nichts anderes, als dich als Menschen im echten Leben zusammenzubringen. Um dies korrekt zu erreichen, müssen wir von dir erfahren, was du gerne im echten Leben machst, wo du studierst oder studiert hast, ob du Student bist und mit welcher Art von Menschen du gerne interagierst.
`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "Kann ich einige der von Join angeforderten Profilinformationen weglassen?",
      answer: `
Wir raten unseren Benutzern im Allgemeinen davon ab, bei der korrekten und angemessenen Präsentation ihres Profils auf Join zu sparen, da dies ihre Erfahrung auf unserer Plattform beeinträchtigen wird. Trotzdem kannst du in vielen Fällen „Keine“ oder „Sonstiges“ als Antwort wählen, falls du die Informationen, nach denen wir fragen, nicht offenlegen möchtest.
`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "Was ist 'Profilstärke'?",
      answer: `
Die Profilstärke beschreibt, wie angemessen und stark dein Profil auf Join basierend auf seinem aktuellen Zustand sein wird. Sie zeigt nicht an, ob es vollständig ist oder nicht, und ist nur für dich sichtbar.
`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "Was sind diese beiden Optionen?",
      answer: `
In diesem Schritt siehst du zwei Optionen.

#### Die erste ist, dein benutzerdefiniertes Event zu erstellen. Klicke auf den -Benutzerdefiniertes Event- Button und erstelle dein ganz eigenes Event, sei es eine Party, ein Sportevent, ein Treffen oder sogar eine Reise!.

#### Die zweite Option ist, nach unten zu wischen, zwischen Places & Verified Events, bis du findest, was du suchst, um deine Gruppe zu erstellen.
`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "Was ist der Unterschied zwischen einem 'benutzerdefinierten Event' und einer 'Gruppe'?",
      answer: `
Ein benutzerdefiniertes Event ist ein Event, das von einem Benutzer – einem anderen Mitglied von Join, genau wie du – erstellt wurde, im Gegensatz zu Verified Events, die ähnlich wie Places sind, da sie vom Join-Team hochgeladen werden.

#### Benutzerdefinierte Events haben ihre eigene Entität und Präsenz, während Gruppen nur unter einem Ort oder einem verifizierten Event aufgelistet werden.
`,
    },
  ],
  es: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "Necesito ayuda para crear mi grupo",
      answer: `
Primero, ten en cuenta que ser el creador de tu propio grupo significa que tienes el control absoluto sobre él, así que no hay necesidad de estresarse, incluso si es el primero que estás creando. Aquí tienes una guía simple.

#### Fecha y Hora
Aquí eliges la fecha y la hora de reunión de tu grupo. No te confundas con las horas a.m.: Por ejemplo, domingo, 00:45 puede parecerte todavía sábado por la noche, pero en Join es domingo por la mañana, y así es como debes listarlo.

#### Invita a tus Amigos
Este es el paso donde inicialmente invitas a tus amigos (no te preocupes, puedes invitar a más después). Los miembros invitados recibirán una notificación de invitación de tu parte, la cual deberán responder afirmativamente si desean unirse al grupo. Puedes crear un grupo sin invitar a nadie y permitir que las personas soliciten unirse a ti.

#### Haz una Declaración
Esta es tu oportunidad de añadir un elemento personal a tu grupo. Esto puede ser algo como “Estamos celebrando mi cumpleaños” o “Las chicas llevan un vestido blanco”.

¡Tu grupo ahora está listo para ser creado!
    `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "Necesito ayuda para crear mi evento",
      answer: `
Primero, ten en cuenta que ser el creador de tu propio evento significa que tienes el control absoluto sobre él, así que no hay necesidad de estresarse, incluso si es el primero que estás creando. Aquí tienes una guía simple.

#### Título del Evento
Esto debe ser una breve descripción de tu evento. Algo como “Fútbol 5x5 en Chalandri”, “Fiesta en una ubicación secreta” o “Encuentro chill en la plaza de Agia Paraskevi”.

#### Describe tu Evento
Ahora es tu oportunidad de describir tu evento. Incluye cualquier detalle que desearías haber visto en un evento como el tuyo, creado por otra persona. Todo lo importante deberías incluirlo aquí. Confía en nosotros, si eres analítico, más personas y más personas adecuadas mostrarán interés en tu evento.

#### Evento Privado
Todos los eventos personalizados son públicos por defecto, lo que significa que todos los usuarios de Join pueden ver e interactuar con tu evento, excepto las personas que has bloqueado. Si cambias tu evento a privado, el acceso se limitará solo a tus amigos y personas que invites. Puedes cambiar la privacidad de tu evento incluso después de su creación.

#### Fecha y Hora
Aquí eliges la fecha y la hora a la que comenzará tu evento. No te confundas con las horas a.m.: Por ejemplo, domingo, 00:45 puede parecerte todavía sábado por la noche, pero en Join es domingo por la mañana, y así es como debes listarlo.

#### Categoría
Hemos concluido que tu evento probablemente caerá en una de estas categorías: Deportes, Fiesta, Viaje o Encuentro. Si no puedes ver claramente que tu evento pertenece a alguna de estas categorías, elige “encuentro”. Después de todo, un evento no puede no ser un encuentro.

#### Invita a tus Amigos
Este es el paso donde inicialmente invitas a tus amigos (no te preocupes, puedes invitar a más después). Los miembros invitados recibirán una notificación de invitación de tu parte, la cual deberán responder afirmativamente si desean unirse al evento. Puedes crear un evento sin invitar a nadie y permitir que las personas soliciten unirse a ti.

¡Tu evento personalizado ahora está listo para ser creado!
`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "¿Puedo crear un grupo privado o unirme a un grupo de forma privada?",
      answer: `
Todos los grupos en Places y Eventos Verificados son públicos. A pesar de eso, puedes cambiar tu perfil a privado, lo que limita tu visibilidad en los grupos a solo tus amigos y miembros de ese grupo específico.
`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "¿Puedo crear un evento privado o unirme a un evento de forma privada?",
      answer: `
A diferencia de los grupos en Places y Eventos Verificados, puedes crear o unirte a Eventos Personalizados privados. Si eres el creador de un evento personalizado, puedes cambiar tu evento de abierto a privado y viceversa en cualquier momento.
`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "¿Estoy haciendo una reserva al crear un grupo?",
      answer: `
En este momento, Join no realiza reservas de mesas para tus grupos.
`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "Dudo en unirme y crear grupos en Join, ya que otras personas sabrán sobre mi vida real – mi vida social",
      answer: `
Entendemos profundamente tu preocupación relacionada con tu privacidad y hacemos nuestro mejor esfuerzo para satisfacer tus necesidades de privacidad, como ofreciéndote la posibilidad de restringir la exposición de tu perfil a través de la configuración de la app o crear eventos privados, mientras mantenemos Join funcional, siendo una app que tiene en su núcleo reunir a las personas en la vida real.
`,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "¿Puedo invitar amigos al evento de otra persona?",
      answer: `
Sí, puedes. Solo si es un evento privado, su creador recibirá una notificación de tu intención de invitar a alguien a su evento y deberá aprobarlo para que la invitación sea válida. En eventos abiertos, al igual que en grupos, cualquier miembro que se haya unido puede invitar libremente a sus amigos sin involucrar al creador.
`,
    },
    {
      id: 8,
      categories: [],
      question:
        "He bloqueado a alguien en Join. ¿Puede ver los grupos y eventos a los que me uno?",
      answer: `
Las personas que has bloqueado en Join no pueden ver si te has unido, creado o recibido una invitación a un grupo o evento, a menos que también tengan relación con ese grupo o evento.
`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "¿Qué veo en la 'Hot Page'?",
      answer: `
El contenido de Join está compuesto por Grupos y Eventos Personalizados. Los grupos pueden crearse bajo “Places” o “Eventos Verificados”, mientras que los Eventos Personalizados son entidades independientes creadas y personalizadas por los propios usuarios. Hemos creado un algoritmo que filtra grupos y eventos personalizados según qué tan bien se ajusta tu perfil a los perfiles en ellos, mezclándolos y presentándolos en consecuencia en la Hot Page.
`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "¿Por qué está pidiendo Join toda esta información del perfil?",
      answer: `
Respetamos tu preocupación sobre la privacidad de tu perfil, mientras nos esforzamos por cumplir nuestro único propósito para nuestros usuarios. Ese es nada menos que reunir a las personas en la vida real. Para lograr esto correctamente, necesitamos que nos cuentes sobre ti, qué te gusta hacer en la vida real, dónde estudias o has estudiado, si eres estudiante y qué tipo de personas prefieres interactuar.
`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "¿Puedo omitir divulgar alguna información del perfil que solicita Join?",
      answer: `
En general, aconsejamos a nuestros usuarios no omitir la presentación correcta y adecuada de sí mismos a través de sus perfiles en Join, ya que esto perjudicará su experiencia en nuestra plataforma. A pesar de eso, en muchas ocasiones, puedes elegir “Ninguno” o “Otro” como respuesta, en caso de que no desees revelar la información que estamos solicitando sobre ti.
`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "¿Qué es la 'Fuerza del Perfil'?",
      answer: `
La Fuerza del Perfil describe cuán adecuado y fuerte será tu perfil en Join, basado en su estado actual. No indica si está completo o no y solo es visible para ti.
`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "¿Cuáles son estas dos opciones?",
      answer: `
En esta etapa, ves dos opciones.

#### La primera es crear tu Evento Personalizado. Haz clic en el botón -Evento Personalizado- y crea tu propio evento, ya sea una fiesta, un evento deportivo, un encuentro o incluso un viaje.

#### La segunda opción es desplazarte hacia abajo, entre Places y Eventos Verificados, hasta que encuentres lo que buscas para crear tu Grupo.
`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "¿Cuál es la diferencia entre un 'Evento Personalizado' y un 'Grupo'?",
      answer: `
Un Evento Personalizado es un evento creado por un usuario -otro miembro de Join, al igual que tú- a diferencia de los Eventos Verificados, que son similares a Places, ya que son subidos por el equipo de Join.

#### Los Eventos Personalizados tienen su propia entidad y presencia, mientras que los Grupos solo se enumeran bajo un Lugar o un Evento Verificado.
`,
    },
  ],
  it: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "Ho bisogno di aiuto per creare il mio gruppo",
      answer: `
Prima di tutto, sappi che essere il creatore del tuo gruppo significa avere il controllo assoluto su di esso, quindi non c'è motivo di stress, anche se è il primo che stai creando. Ecco una guida semplice.

#### Data e Ora
Qui scegli la data e l'orario di incontro del tuo gruppo. Non confonderti con gli orari a.m.: Ad esempio, domenica, 00:45 potrebbe sembrare ancora sabato sera, ma su Join è domenica mattina e così devi elencarlo.

#### Invita i tuoi Amici
Questo è il passo in cui inizialmente inviti i tuoi amici (non preoccuparti, puoi invitare altri in seguito). I membri invitati riceveranno una notifica di invito da parte tua, alla quale dovranno rispondere affermativamente se vogliono unirsi al gruppo. Puoi creare un gruppo senza invitare nessuno e far sì che le persone richiedano di unirsi a te.

#### Fai una Dichiarazione
Questa è la tua opportunità per aggiungere un tocco personale al tuo gruppo. Può essere qualcosa come “Stiamo festeggiando il mio compleanno” o “Le ragazze indossano un vestito bianco”.

Il tuo gruppo è ora pronto per essere creato!
    `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "Ho bisogno di aiuto per creare il mio evento",
      answer: `
Prima di tutto, sappi che essere il creatore del tuo evento significa avere il controllo assoluto su di esso, quindi non c'è motivo di stress, anche se è il primo che stai creando. Ecco una guida semplice.

#### Titolo dell'Evento
Questo deve essere una breve descrizione del tuo evento. Qualcosa come “Calcio 5x5 a Chalandri”, “Festa in una location segreta” o “Incontro chill nella piazza di Agia Paraskevi”.

#### Descrivi il tuo Evento
Ora è il tuo momento per descrivere il tuo evento. Includi qualsiasi dettaglio che avresti voluto vedere in un evento simile al tuo, creato da qualcun altro. Tutto ciò che è importante dovrebbe essere incluso qui. Fidati di noi, se sei analitico, più persone e più adatte mostreranno interesse per il tuo evento.

#### Evento Privato
Tutti gli eventi personalizzati sono pubblici per impostazione predefinita, il che significa che tutti gli utenti di Join possono vedere e interagire con il tuo evento, tranne le persone che hai bloccato. Se cambi il tuo evento in privato, l'accesso sarà limitato solo ai tuoi amici e alle persone che inviti. Puoi modificare la privacy del tuo evento anche dopo la sua creazione.

#### Data e Ora
Qui scegli la data e l'ora in cui inizierà il tuo evento. Non confonderti con gli orari a.m.: Ad esempio, domenica, 00:45 potrebbe sembrarti ancora sabato sera, ma su Join è domenica mattina e così devi elencarlo.

#### Categoria
Abbiamo concluso che il tuo evento rientrerà probabilmente in una di queste categorie: Sport, Festa, Viaggio o Incontro. Se non riesci a vedere chiaramente che il tuo evento appartiene a una di queste categorie, scegli “incontro”. Dopotutto, un evento non può non essere un incontro.

#### Invita i tuoi Amici
Questo è il passo in cui inizialmente inviti i tuoi amici (non preoccuparti, puoi invitare altri in seguito). I membri invitati riceveranno una notifica di invito da parte tua, alla quale dovranno rispondere affermativamente se vogliono unirsi all'evento. Puoi creare un evento senza invitare nessuno e permettere che le persone richiedano di unirsi a te.

Il tuo evento personalizzato è ora pronto per essere creato!
`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "Posso creare un gruppo privato o unirmi a un gruppo in modo privato?",
      answer: `
Tutti i gruppi in Places e Eventi Verificati sono pubblici. Nonostante ciò, puoi cambiare il tuo profilo in privato, il che limita la tua visibilità nei gruppi solo ai tuoi amici e ai membri di quel gruppo specifico.
`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "Posso creare un evento privato o unirmi a un evento in modo privato?",
      answer: `
A differenza dei gruppi in Places e Eventi Verificati, puoi creare o unirti a Eventi Personalizzati privati. Se sei il creatore di un evento personalizzato, puoi cambiare il tuo evento da pubblico a privato e viceversa in qualsiasi momento.
`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Sto facendo una prenotazione quando creo un gruppo?",
      answer: `
In questo momento, Join non effettua prenotazioni di tavoli per i tuoi gruppi.
`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "Sono titubante nel unirmi e creare gruppi su Join, poiché altre persone sapranno della mia vita reale – vita sociale",
      answer: `
Comprendiamo profondamente la tua preoccupazione riguardo alla tua privacy e ci impegniamo al massimo per soddisfare le tue esigenze di privacy, offrendoti la possibilità di limitare l'esposizione del tuo profilo tramite le impostazioni dell'app o di creare eventi privati, mantenendo Join funzionale, essendo un'app che ha come obiettivo principale riunire le persone nella vita reale.
`,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Posso invitare amici a un evento di qualcun altro?",
      answer: `
Sì, puoi. Solo se è un evento privato, il suo creatore riceverà una notifica della tua intenzione di invitare qualcuno al loro evento e dovrà approvarlo affinché l'invito sia valido. Negli eventi aperti, proprio come nei gruppi, qualsiasi membro che si è unito può invitare liberamente i propri amici senza coinvolgere il creatore.
`,
    },
    {
      id: 8,
      categories: [],
      question:
        "Ho bloccato qualcuno su Join. Possono vedere i gruppi e gli eventi a cui mi unisco?",
      answer: `
Le persone che hai bloccato su Join non possono vedere se ti sei unito, creato o ricevuto un invito a un gruppo o a un evento, a meno che non abbiano anche una relazione con quel gruppo o evento.
`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "Cosa vedo nella 'Hot Page'?",
      answer: `
Il contenuto di Join è composto da Gruppi ed Eventi Personalizzati. I gruppi possono essere creati sotto “Places” o “Eventi Verificati”, mentre gli Eventi Personalizzati sono entità indipendenti create e personalizzate dagli utenti stessi. Abbiamo creato un algoritmo che filtra gruppi ed eventi personalizzati in base a quanto bene il tuo profilo corrisponde ai profili in essi, mescolandoli e presentandoli di conseguenza nella Hot Page.
`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Perché Join richiede tutte queste informazioni sul profilo?",
      answer: `
Rispettiamo la tua preoccupazione riguardo alla privacy del tuo profilo, mentre ci sforziamo di adempiere al nostro unico scopo per i nostri utenti. Non è altro che riunire le persone nella vita reale. Per raggiungere questo obiettivo correttamente, abbiamo bisogno che ci racconti di te, cosa ti piace fare nella vita reale, dove studi o hai studiato, se sei uno studente e che tipo di persone preferisci con cui interagire.
`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "Posso omettere di rivelare alcune informazioni del profilo richieste da Join?",
      answer: `
In generale, consigliamo ai nostri utenti di non saltare la presentazione corretta e adeguata di se stessi tramite i loro profili su Join, poiché ciò danneggerà la loro esperienza sulla nostra piattaforma. Nonostante ciò, in molte occasioni, puoi scegliere “Nessuno” o “Altro” come risposta, nel caso in cui tu non voglia rivelare le informazioni che stiamo chiedendo su di te.
`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "Cos'è la 'Forza del Profilo'?",
      answer: `
La Forza del Profilo rappresenta quanto il tuo profilo sarà adeguato e forte su Join, basandosi sul suo stato attuale. Non indica se è completo o meno ed è visibile solo a te.
`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "Quali sono queste due opzioni?",
      answer: `
In questa fase, vedi due opzioni.

#### La prima è creare il tuo Evento Personalizzato. Clicca sul pulsante -Evento Personalizzato- e crea il tuo evento, sia esso una festa, un evento sportivo, un incontro o persino un viaggio!.

#### La seconda opzione è scorrere verso il basso, tra Luoghi ed Eventi Verificati fino a trovare ciò che stai cercando per creare il tuo Gruppo.
`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "Qual è la differenza tra un 'Evento Personalizzato' e un 'Gruppo'?",
      answer: `
Un Evento Personalizzato è un evento creato da un utente -un altro membro di Join proprio come te- a differenza degli Eventi Verificati che sono simili a Luoghi, poiché sono caricati dal team di Join.

#### Gli Eventi Personalizzati hanno la propria entità e presenza, mentre i Gruppi vengono elencati solo sotto un Luogo o un Evento Verificato.
`,
    },
  ],
  ru: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "Мне нужна помощь в создании группы",
      answer: `
Прежде всего, знайте, что будучи создателем вашей группы, вы имеете абсолютный контроль над ней, поэтому нет необходимости нервничать, даже если это ваша первая группа. Вот простое руководство.

#### Дата и Время
Здесь вы выбираете дату и время встречи вашей группы. Не путайте время в a.m.: например, воскресенье, 00:45 может все еще казаться вам субботним вечером, но в Join это воскресенье утром, и именно так вы должны это указать.

#### Пригласите своих Друзей
Это шаг, на котором вы сначала приглашаете своих друзей (не переживайте, вы можете пригласить больше позже). Приглашенные члены получат уведомление об приглашении от вас, на которое они должны ответить утвердительно, если хотят присоединиться к группе. Вы можете создать группу, не приглашая никого, и позволить людям запросить присоединение.

#### Сделайте Заявление
Это ваш шанс добавить элемент личного подхода к вашей группе. Это может быть что-то вроде “Мы отмечаем мой день рождения” или “Девушки надевают белое платье”.

Ваша группа теперь готова к созданию!
      `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "Мне нужна помощь в создании события",
      answer: `
Прежде всего, знайте, что будучи создателем своего события, вы имеете абсолютный контроль над ним, поэтому нет необходимости нервничать, даже если это ваше первое событие. Вот простое руководство.

#### Название События
Это должно быть краткое описание вашего события. Что-то вроде “Футбол 5x5 в Халандри”, “Тайная пляжная вечеринка” или “Чил-встреча на площади Агиа Параскеви”.

#### Опишите свое Событие
Теперь у вас есть возможность описать ваше событие. Включите любые детали, которые вы хотели бы увидеть на событии, подобном вашему, созданном кем-то другим. Все важные моменты следует указать здесь. Доверьтесь нам, если вы аналитичны, то больше людей и более подходящих людей проявят интерес к вашему событию.

#### Частное Событие
Все пользовательские события по умолчанию открыты, что означает, что все пользователи Join могут видеть и взаимодействовать с вашим событием, кроме людей, которых вы заблокировали. Если вы измените ваше событие на частное, доступ будет ограничен только вашими друзьями и людьми, которых вы пригласите. Вы можете изменить настройки конфиденциальности вашего события даже после его создания.

#### Дата и Время
Здесь вы выбираете дату и время начала вашего события. Не путайте время в a.m.: например, воскресенье, 00:45 может казаться вам еще субботним вечером, но в Join это воскресенье утром, и именно так вы должны это указать.

#### Категория
Мы пришли к выводу, что ваше событие, вероятно, будет относиться к одной из следующих категорий: Спорт, Вечеринка, Путешествие или Встреча. Если вы не можете четко увидеть, к какой из этих категорий относится ваше событие, выберите “встреча”. В конце концов, событие не может не быть встречей.

#### Пригласите своих Друзей
Это шаг, на котором вы сначала приглашаете своих друзей (не переживайте, вы можете пригласить больше позже). Приглашенные члены получат уведомление об приглашении от вас, на которое они должны ответить утвердительно, если хотят присоединиться к событию. Вы можете создать событие, не приглашая никого, и позволить людям запросить присоединение.

Ваше пользовательское событие теперь готово к созданию!
`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "Могу ли я создать частную группу или присоединиться к группе приватно?",
      answer: `
Все группы в Places и Проверенных Событиях являются публичными. Тем не менее, вы можете изменить свой профиль на частный, что ограничит ваше появление в группах только вашими друзьями и участниками конкретной группы.
`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "Могу ли я создать частное событие или присоединиться к событию приватно?",
      answer: `
В отличие от групп в Places и Проверенных Событиях, вы можете создавать или присоединяться к Частным Пользовательским Событиям. Если вы являетесь создателем пользовательского события, вы можете в любое время изменить его с открытого на частное и наоборот.
`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Делаю ли я резервирование, создавая группу?",
      answer: `
На данный момент Join не делает резервирования столиков для ваших групп.
`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "Я колеблюсь, чтобы присоединиться и создавать группы на Join, так как другие люди тогда будут знать о моей реальной жизни – социальной жизни",
      answer: `
Мы глубоко понимаем вашу озабоченность по поводу вашей конфиденциальности и стараемся изо всех сил удовлетворить ваши потребности в конфиденциальности, предлагая вам возможность ограничить видимость вашего профиля через настройки приложения или создавать частные события, сохраняя функциональность Join, будучи приложением, основная цель которого — объединять людей в реальной жизни.
  `,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Могу ли я пригласить друзей на событие другого человека?",
      answer: `
Да, можете. Только если это частное событие, его создатель получит уведомление о вашем намерении пригласить кого-то на их событие и должен будет его одобрить, чтобы приглашение прошло. В открытых событиях, как и в группах, любой участник может свободно приглашать своих друзей без участия создателя.
`,
    },
    {
      id: 8,
      categories: [],
      question:
        "Я заблокировал кого-то на Join. Могут ли они видеть группы и события, к которым я присоединяюсь?",
      answer: `
Люди, которых вы заблокировали на Join, не могут видеть, присоединились ли вы к группе или событию, создали ли их или получили ли приглашение, если они не имеют отношения к этой группе или событию.
`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "Что я вижу на 'Горячей странице'?",
      answer: `
Содержимое Join состоит из Групп и Пользовательских Событий. Группы могут создаваться в разделе “Places” или “Проверенные События”, в то время как Пользовательские События — это самостоятельные сущности, созданные и настроенные самими пользователями. Мы разработали алгоритм, который фильтрует группы и пользовательские события в зависимости от того, насколько хорошо ваш профиль соответствует профилям в них, смешивая и представляя их соответственно на Горячей странице.
`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "Почему Join запрашивает всю эту информацию о профиле?",
      answer: `
Мы уважаем вашу озабоченность по поводу конфиденциальности вашего профиля, стремясь выполнить нашу единственную цель для наших пользователей. А именно — собрать вас, как людей, вместе в реальной жизни. Чтобы правильно добиться этого, нам нужно, чтобы вы рассказали о себе, чем вам нравится заниматься в реальной жизни, где вы учитесь или учились, являетесь ли вы студентом и с какими людьми предпочитаете взаимодействовать.
`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "Могу ли я не раскрывать некоторые запрашиваемые Join сведения о профиле?",
      answer: `
В общем, мы советуем нашим пользователям не пропускать правильное и адекватное представление себя через свои профили на Join, так как это повредит их опыту на нашей платформе. Тем не менее, во многих случаях вы можете выбрать “Ничего” или “Другое” в качестве ответа, если не хотите раскрывать информацию, которую мы запрашиваем о вас.
`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "Что такое 'Сила Профиля'?",
      answer: `
Сила Профиля показывает, насколько адекватным и сильным будет ваш профиль на Join, исходя из его текущего состояния. Она не показывает, является ли он полным или нет и видна только вам.
`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "Что это за две опции?",
      answer: `
На этом этапе вы видите две опции.

#### Первая — создать ваше Пользовательское Событие. Нажмите на кнопку -Пользовательское Событие- и создайте ваше собственное событие, будь то вечеринка, спортивное событие, встреча или даже поездка!.

#### Вторая опция — прокрутить вниз, между Местами и Проверенными Событиями, пока не найдете то, что ищете для создания вашей Группы.
`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question: "В чем разница между 'Пользовательским Событием' и 'Группой'?",
      answer: `
Пользовательское Событие — это событие, созданное пользователем — другим участником Join, таким же, как и вы, в отличие от Проверенных Событий, которые аналогичны Местам, так как они загружаются командой Join.

#### Пользовательские События имеют свою собственную сущность и присутствие, тогда как Группы перечисляются только под Местом или Проверенным Событием.
`,
    },
  ],
  fr: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "J'ai besoin d'aide pour créer mon groupe",
      answer: `
Tout d'abord, sachez qu'en étant le créateur de votre propre groupe, vous avez un contrôle total sur celui-ci, donc il n'y a pas besoin de stress, même si c'est le premier que vous créez. Voici un guide simple.

#### Date et Heure
Ici, vous choisissez la date et l'heure de votre réunion de groupe. Ne vous laissez pas confondre par les heures a.m. : par exemple, dimanche, 00:45 peut encore vous sembler être samedi soir, mais sur Join, c'est dimanche matin et c'est ainsi que vous devez le mentionner.

#### Invitez vos Amis
C'est l'étape où vous invitez initialement vos amis (ne vous inquiétez pas, vous pouvez inviter d'autres personnes par la suite). Les membres invités recevront une notification d'invitation de votre part, à laquelle ils doivent répondre de manière affirmative s'ils souhaitent rejoindre le groupe. Vous pouvez créer un groupe sans inviter personne et permettre aux gens de demander à vous rejoindre.

#### Faites une Déclaration
C'est votre chance d'ajouter un élément personnel à votre groupe. Cela peut être quelque chose comme "Nous fêtons mon anniversaire" ou "Les filles portent une robe blanche".

Votre groupe est maintenant prêt à être créé !
    `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "J'ai besoin d'aide pour créer mon événement",
      answer: `
Tout d'abord, sachez qu'en étant le créateur de votre propre événement, vous avez un contrôle total sur celui-ci, donc il n'y a pas besoin de stress, même si c'est le premier que vous créez. Voici un guide simple.

#### Titre de l'Événement
Cela doit être une brève description de votre événement. Quelque chose comme "Football 5x5 à Chalandri", "Fête sur une plage à lieu secret", ou "Rencontre détente sur la place Agia Paraskevi".

#### Décrivez votre Événement
Maintenant, c'est votre chance de décrire votre événement. Incluez tous les détails que vous auriez souhaité voir à un événement similaire, créé par quelqu'un d'autre. Tout ce qui est important, vous devriez l'inclure ici. Faites-nous confiance, si vous êtes analytique, alors plus de gens et des gens plus adaptés montreront de l'intérêt pour votre événement.

#### Événement Privé
Tous les événements personnalisés sont ouverts par défaut, ce qui signifie que tous les utilisateurs de Join peuvent voir et interagir avec votre événement, sauf les personnes que vous avez bloquées. Si vous changez votre événement en privé, l'accès sera limité à seulement vos amis et aux personnes que vous invitez. Vous pouvez modifier la confidentialité de votre événement même après sa création.

#### Date et Heure
Ici, vous choisissez la date et l'heure de début de votre événement. Ne vous laissez pas confondre par les heures a.m. : par exemple, dimanche, 00:45 peut sembler être encore samedi soir dans votre esprit, mais sur Join, c'est dimanche matin et c'est ainsi que vous devez le mentionner.

#### Catégorie
Nous avons conclu que votre événement relèvera très probablement de l'une de ces catégories : Sport, Fête, Voyage ou Rencontre. Si vous ne pouvez pas clairement voir à quelle catégorie votre événement appartient, veuillez choisir "rencontre". Après tout, un événement ne peut pas ne pas être une rencontre.

#### Invitez vos Amis
C'est l'étape où vous invitez initialement vos amis (ne vous inquiétez pas, vous pouvez inviter d'autres personnes par la suite). Les membres invités recevront une notification d'invitation de votre part, à laquelle ils doivent répondre de manière affirmative s'ils souhaitent rejoindre l'événement. Vous pouvez créer un événement sans inviter personne et permettre aux gens de demander à vous rejoindre.

Votre événement personnalisé est maintenant prêt à être créé !
`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question:
        "Puis-je créer un groupe privé ou rejoindre un groupe de manière privée ?",
      answer: `
Tous les groupes dans Places et Événements Vérifiés sont publics. Cependant, vous pouvez changer votre profil en privé, ce qui limite votre apparition dans les groupes à seulement vos amis et aux membres qui ont rejoint ce groupe spécifique.
`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question:
        "Puis-je créer un événement privé ou rejoindre un événement de manière privée ?",
      answer: `
Contrairement aux groupes dans Places et Événements Vérifiés, vous pouvez créer ou rejoindre des Événements Personnalisés Privés. Si vous êtes le créateur d'un événement personnalisé, vous pouvez à tout moment passer votre événement de public à privé et vice versa.
`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "Est-ce que je fais une réservation en créant un groupe ?",
      answer: `
Pour le moment, Join ne fait pas de réservations de tables pour vos groupes.
`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "J'hésite à rejoindre et créer des groupes sur Join, car d'autres personnes sauront alors sur ma vie réelle – ma vie sociale",
      answer: `
Nous comprenons profondément votre préoccupation concernant votre vie privée et faisons de notre mieux pour répondre à vos besoins de confidentialité, par exemple en vous offrant la possibilité de restreindre l'exposition de votre profil via les paramètres de l'application ou de créer des événements privés, tout en maintenant Join fonctionnel, étant une application dont le but est de rassembler des personnes dans la vie réelle.
`,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "Puis-je inviter des amis à l'événement de quelqu'un d'autre ?",
      answer: `
Oui, vous pouvez. Seulement si c'est un événement privé, son créateur recevra une notification de votre intention d'inviter quelqu'un à son événement et doit l'approuver pour que l'invitation soit acceptée. Dans les événements ouverts, tout comme dans les groupes, tout membre participant peut librement inviter ses amis sans consulter le créateur.
`,
    },
    {
      id: 8,
      categories: [],
      question:
        "J'ai bloqué quelqu'un sur Join. Peut-il voir les groupes et événements auxquels je participe ?",
      answer: `
Les personnes que vous avez bloquées sur Join ne peuvent pas voir si vous avez rejoint, créé ou été invité à un groupe ou un événement, à moins qu'elles ne soient également liées à ce groupe ou événement.
`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "Que vois-je dans la 'Page Chaude' ?",
      answer: `
Le contenu de Join est composé de Groupes et d'Événements Personnalisés. Les Groupes peuvent être créés sous “Places” ou sous “Événements Vérifiés”, tandis que les Événements Personnalisés sont des entités autonomes créées et personnalisées par les utilisateurs eux-mêmes. Nous avons créé un algorithme qui filtre les groupes et événements personnalisés en fonction de la manière dont votre profil correspond aux profils qu'ils contiennent, les mélange et les présente en conséquence sur la Page Chaude.
`,
    },
    {
      id: 10,
      categories: ["profile"],
      question:
        "Pourquoi Join demande-t-il toutes ces informations sur mon profil ?",
      answer: `
Nous respectons votre préoccupation concernant la confidentialité de votre profil, tout en nous efforçant de remplir notre unique objectif pour nos utilisateurs. Il n'est rien d'autre que de vous rassembler, en tant que personnes, dans la vie réelle. Pour atteindre cela correctement, nous avons besoin que vous nous parliez de vous, de ce que vous aimez faire dans la vie réelle, où vous étudiez ou avez étudié, si vous êtes étudiant et quel type de personnes vous préférez côtoyer.
`,
    },
    {
      id: 11,
      categories: ["profile"],
      question:
        "Puis-je omettre de divulguer certaines informations sur mon profil demandées par Join ?",
      answer: `
Nous conseillons généralement à nos utilisateurs de ne pas négliger de se présenter correctement et adéquatement via leurs profils sur Join, car cela nuira à leur expérience sur notre plateforme. Cependant, dans de nombreux cas, vous pouvez choisir "Aucun" ou "Autre" comme réponse, si vous ne souhaitez pas divulguer les informations que nous demandons à votre sujet.
`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "Qu'est-ce que la 'Force du Profil' ?",
      answer: `
La Force du Profil représente à quel point votre profil sera adéquat et fort sur Join, en fonction de son état actuel. Elle ne montre pas s'il est complet ou non et est visible uniquement par vous.
`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "Quelles sont ces deux options ?",
      answer: `
À ce stade, vous voyez deux options.

#### La première est de créer votre Événement Personnalisé. Cliquez sur le bouton -Événement Personnalisé- et créez votre propre événement, qu'il s'agisse d'une fête, d'un événement sportif, d'une rencontre ou même d'un voyage !

#### La seconde option est de faire défiler vers le bas, entre Places et Événements Vérifiés, jusqu'à ce que vous trouviez ce que vous recherchez pour créer votre Groupe.
`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question:
        "Quelle est la différence entre un 'Événement Personnalisé' et un 'Groupe' ?",
      answer: `
Un Événement Personnalisé est un événement créé par un utilisateur -un autre membre de Join comme vous- contrairement aux Événements Vérifiés qui sont similaires aux Places, car ils sont téléchargés par l'équipe de Join.

#### Les Événements Personnalisés ont leur propre entité et présence, tandis que les Groupes ne sont répertoriés que sous un Lieu ou un Événement Vérifié.
`,
    },
  ],
  zh: [
    {
      id: 1,
      categories: ["groupCreation", "interact"],
      question: "我需要帮助来创建我的小组",
      answer: `
首先，请知道作为自己小组的创建者意味着你对其拥有绝对的控制权，所以即使这是你第一次创建小组，也无需感到压力。以下是一个简单的指南。

#### 日期和时间
在这里，您选择小组的日期和会议时间。不要被上午的时间搞混：例如，周日00:45在你心中可能仍然是周六晚上，但在Join上是周日早上，您需要这样列出。

#### 邀请你的朋友
这是您最初邀请朋友的步骤（别担心，您可以在之后邀请更多人）。被邀请的成员将收到来自您的邀请通知，只有在他们确认后才能加入小组。您可以在不邀请任何人的情况下创建小组，并让人们请求加入您。

#### 做一个声明
这是您为小组添加个人色彩的机会。这可以是像“我们在庆祝我的生日”或“女孩们穿白色裙子”这样的内容。

您的小组现在准备创建了！
    `,
    },
    {
      id: 2,
      categories: ["eventCreation", "interact", "eventsMore"],
      question: "我需要帮助来创建我的活动",
      answer: `
首先，请知道作为自己活动的创建者意味着你对其拥有绝对的控制权，所以即使这是你第一次创建活动，也无需感到压力。以下是一个简单的指南。

#### 活动标题
这必须是您活动的简要描述。像“Chalandri的5x5足球”、“秘密地点的海滩派对”或“Agia Paraskevi广场的休闲聚会”这样的内容。

#### 描述您的活动
现在是您描述活动的机会。包括您希望在别人创建的类似活动中看到的任何细节。所有重要的内容都应在此包含。相信我们，如果您分析得当，那么更多的人和更适合的人将会对您的活动表现出兴趣。

#### 私密活动
所有自定义活动默认是公开的，意味着所有Join的用户都可以查看和参与您的活动，除了您已经屏蔽的人。如果您将活动切换为私密，则访问将仅限于您的朋友和您邀请的人。您可以在创建后更改活动的隐私设置。

#### 日期和时间
在这里，您选择活动的开始日期和时间。不要被上午的时间搞混：例如，周日00:45在你心中可能仍然是周六晚上，但在Join上是周日早上，您需要这样列出。

#### 类别
我们得出结论，您的活动很可能属于以下类别之一：运动、派对、旅行或聚会。如果您无法明确看到您的活动属于任何类别，请选择“聚会”。毕竟，活动不可能不是聚会。

#### 邀请你的朋友
这是您最初邀请朋友的步骤（别担心，您可以在之后邀请更多人）。被邀请的成员将收到来自您的邀请通知，只有在他们确认后才能加入活动。您可以在不邀请任何人的情况下创建活动，并让人们请求加入您。

您的自定义活动现在准备创建了！
`,
    },
    {
      id: 3,
      categories: ["groupCreation", "groupPage", "interact"],
      question: "我可以创建一个私密小组或私密加入小组吗？",
      answer: `
在Places和Verified Events中的所有小组都是公开的。尽管如此，您可以将个人资料更改为私密，这样您的小组显示将仅限于您的朋友和该特定小组的已加入成员。
`,
    },
    {
      id: 4,
      categories: ["eventCreation", "eventPage", "interact", "eventsMore"],
      question: "我可以创建一个私密活动或私密加入活动吗？",
      answer: `
与Places和Verified Events中的小组不同，您可以创建或加入私密的自定义活动。如果您是自定义活动的创建者，您可以随时将活动从公开切换为私密，反之亦然。
`,
    },
    {
      id: 5,
      categories: ["groupCreation", "interact"],
      question: "创建小组时我是在进行预订吗？",
      answer: `
目前，Join不为您的小组进行桌子预订。
`,
    },
    {
      id: 6,
      categories: [
        "groupCreation",
        "eventCreation",
        "groupPage",
        "eventPage",
        "interact",
      ],
      question:
        "我对在Join上加入和创建小组感到犹豫，因为其他人会知道我的真实生活——社交生活",
      answer: `
我们深刻理解您对隐私的担忧，并尽力满足您的隐私需求，例如通过应用程序的设置限制您的个人资料曝光，或创建私密活动，同时保持Join的功能性，作为一款将人们在现实生活中聚集在一起的应用。
`,
    },
    {
      id: 7,
      categories: ["eventPage", "interact", "eventsMore"],
      question: "我可以邀请朋友参加别人的活动吗？",
      answer: `
是的，您可以。只有在活动是私密的情况下，活动创建者会收到您邀请某人参加他们活动的通知，并且必须批准才能完成邀请。在公开活动中，就像在小组中一样，任何已加入的成员都可以自由邀请他们的朋友，而不需要与创建者沟通。
`,
    },
    {
      id: 8,
      categories: [],
      question: "我在Join上屏蔽了某人。他们可以看到我加入的组和活动吗？",
      answer: `
您在Join上屏蔽的人无法看到您是否已加入、创建或受邀参加某个小组或活动，除非他们也与该小组或活动相关联。
`,
    },
    {
      id: 9,
      categories: ["interact", "eventsMore"],
      question: "我在'热门页面'上看到什么？",
      answer: `
Join的内容由小组和自定义活动组成。小组可以在“Places”或“Verified Events”下创建，而自定义活动是由用户自行创建和定制的独立实体。我们创建了一个算法，根据您的个人资料与其中的个人资料的匹配程度，过滤小组和自定义活动，并相应地在热门页面中混合和呈现它们。
`,
    },
    {
      id: 10,
      categories: ["profile"],
      question: "为什么Join会询问我所有这些个人资料信息？",
      answer: `
我们尊重您对个人资料隐私的担忧，同时努力向我们的用户提供我们唯一的目的。那就是将您作为人聚集在现实生活中。为了正确实现这一目标，我们需要您告诉我们关于您自己、您在现实生活中喜欢做什么、您在哪里学习或曾经学习、您是否是学生以及您喜欢与什么类型的人互动的信息。
`,
    },
    {
      id: 11,
      categories: ["profile"],
      question: "我可以省略Join要求的某些个人资料信息吗？",
      answer: `
我们通常建议用户不要忽视通过个人资料在Join上准确和充分地展示自己，因为这会损害他们在我们平台上的体验。尽管如此，在许多情况下，您可以选择“无”或“其他”作为答案，如果您不想披露我们要求的关于您的信息。
`,
    },
    {
      id: 12,
      categories: ["profile"],
      question: "什么是'个人资料强度'？",
      answer: `
个人资料强度表示您在Join上个人资料的适当性和强度，基于其当前状态。它并不表示个人资料是否完整，且仅对您可见。
`,
    },
    {
      id: 13,
      categories: ["hotCreate"],
      question: "这两个选项是什么？",
      answer: `
在这个阶段，您看到两个选项。

#### 第一个是创建您的自定义活动。点击 -自定义活动- 按钮，创建您自己的活动，无论是派对、体育活动、聚会还是旅行！

#### 第二个选项是向下滚动，在“Places”和“Verified Events”之间，直到您找到您想要创建小组的内容。
`,
    },
    {
      id: 14,
      categories: ["hotCreate", "groupPage"],
      question: "“自定义活动”和“组”之间有什么区别？",
      answer: `
自定义活动是由用户创建的活动——与您一样的Join成员——而验证的活动则类似于地点，因为它们由Join团队上传。

#### 自定义活动有其自身的实体和存在，而小组仅在一个地点或验证的活动下列出。
`,
    },
  ],
};

export { getFaqs };
