import React, { useState, useEffect } from "react";
import logo from "../../assets/joinlogo.png";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import platform from "platform";
import Countdown from "react-countdown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; // Import Button
import "../../assets/CountdownTimer.css";
import "../../assets/App.css";
import { useTranslation } from "react-i18next";
import iosLogo from "../../assets/ios.svg";
import androidLogo from "../../assets/android.png";

export default function FirstSection() {
  const { t, i18n } = useTranslation();
  const os = platform.os.family;
  const [isCountdownFinished, setIsCountdownFinished] = useState(false);
  const [isWebView, setIsWebView] = useState(false);

  const padWithZeros = (number, minLength) => {
    const numberString = number.toString();
    return numberString.length >= minLength
      ? numberString
      : "0".repeat(minLength - numberString.length) + numberString;
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCountdownFinished(true);
      // Check if the OS is Android or iOS and display the respective button
      return (
        <Stack
        spacing={1}
        justifyContent="center"
        direction="row"
        sx={{ pt: 1 }} 
      >
        {os === "Android" ? (
          <img
            src={androidLogo}
            alt="google_play"
            onClick={() => console.log("playstore")}
            style={{ width: 200 }}
          />
        ) : os === "iOS" ? (
          <img
            src={iosLogo} // Default to iOS image
            alt="app_store"
            onClick={() => console.log("appstore")}
            style={{ width: 160 }}
          />
        ) : (
          // Default option if the platform is not Android or iOS
          <>
            <img
              src={iosLogo} // Default to iOS image
              alt="app_store"
              onClick={() => console.log("appstore")}
              style={{ width: 160 }}
            />
            <img
              src={androidLogo}
              alt="google_play"
              onClick={() => console.log("playstore")}
              style={{ width: 200 }}
            />
          </>
        )}
      </Stack>
      
      );
    } else {
      return (
        <Stack spacing={4} justifyContent="center" direction="row">
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(days, 2)}</span>
            <span className="label">{t("Days")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(hours, 2)}</span>
            <span className="label">{t("Hours")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(minutes, 2)}</span>
            <span className="label">{t("Minutes")}</span>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <span className="value">{padWithZeros(seconds, 2)}</span>
            <span className="label">{t("Seconds")}</span>
          </Stack>
        </Stack>
      );
    }
  };

  const handleCountdownComplete = () => {
    setIsCountdownFinished(true);
  };

  const detectWebView = () => {
    const userAgent = navigator.userAgent || window.opera;
    if (
      userAgent.includes("FBAN") || // Facebook App
      userAgent.includes("FBAV") || // Facebook Messenger App
      userAgent.includes("Instagram") || // Instagram App
      userAgent.includes("Line") || // Line App
      userAgent.includes("Snapchat") // Snapchat App
    ) {
      setIsWebView(true);
    }
  };

  useEffect(() => {
    detectWebView();
  }, []);

  const handleOpenInApp = () => {
      const appDeepLink = `join-socialapp:/${window.location.pathname}${window.location.search}`;
    console.log(appDeepLink);

    // Fallback URLs for App Store and Google Play
    const fallbackUrl =
      platform.os.family === "Android"
        ? "https://play.google.com/store/apps/details?id=your.android.app.id" // TODO Replace with your actual Android app ID
        : "https://apps.apple.com/app/idyouriosappid"; // TODO Replace with your actual iOS app ID

    // Try to open the app using deep link
    window.location.href = appDeepLink; // This is more reliable than window.open for deep links

    // Wait a bit before redirecting to the fallback URL
    setTimeout(() => {
      window.location.href = fallbackUrl; // Redirect to store after a delay if app is not opened
    }, 2500); // Adjust the delay time as needed
  };

  return (
    <Stack
      spacing={{ xs: 0, sm: 2 }}
      alignItems="center"
      marginTop={{ xs: 13, sm: 25 }}
      paddingInline={4}
      sx={{
        background: "linear-gradient(to bottom, white 40%, lightblue)",
        padding: 4,
        borderRadius: 2,
      }}
      
    >
      {isWebView && ( // Only display the button if isWebView is true
        <Button
          variant="contained"
          onClick={handleOpenInApp}
          sx={{
            borderRadius: "20px",
            width: "80%",
            maxWidth: "400px",
            backgroundColor: "rgba(3, 169, 252, 0.1)",
            border: "2px solid #03a9fc",
            color: "#03a9fc",
            "&:hover": {
              backgroundColor: "rgba(176, 224, 230, 0.1)",
            },
          }}
        >
          {t("OpenInApp")}
        </Button>
      )}

      <img
        className="App-logo"
        src={logo}
        alt="Join Trademark - Mobile app for socializing, joining events, meeting people, and dating"
        title="Trademark of Join - A Mobile App for Real Socializing, Events, and Dating"
        style={{
          paddingTop: isWebView ? "50px" : "0px",
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: 26, md: 40 },
            fontFamily:
              i18n.language === "el" ? "Manrope Regular" : "Trueno Light",
            letterSpacing: 1,
            color: "#1EABF1",
            textAlign: "center",
          }}
        >
          <pre style={{ fontFamily: "inherit" }}>{t("Slogan")}</pre>
        </Typography>
      </Box>

      <Typography
        variant="body1"
        align="center"
        color="#57606f"
        fontFamily={
          i18n.language === "el" ? "OpenSans MediumItalic" : "Montserrat Italic"
        }
      >
        {isCountdownFinished ? t("AvailableNow") : t("AvailableIn")}
      </Typography>

      <Countdown
        date={new Date(2024, 11, 20)}
        renderer={renderer}
        onComplete={handleCountdownComplete}
      />
    </Stack>
  );
}
